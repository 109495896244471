import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { userAuthentication } from "../redux/user/user.actions";
import Spinner from "../components/Spinner";
import { setError, setProgress } from "../redux/public/public.actions";

const PageContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        md:bg-sky-100
        h-[100vh]
    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-4xl
        font-bold
        text-sky-600
        col-span-1
    `}
`
const FormContainer = styled.div`
    ${tw`
        m-auto
        bg-white
        lg:w-[30%]
        md:w-[80%]
        w-full
        md:p-10
        p-5
        rounded-md
        lg:drop-shadow-lg
        lg:mt-[100px]
        md:mt-[50px]
    `}
`

const RegisterForm = styled.div`
    ${tw`
        grid
        grid-cols-1
        md:gap-5
        gap-3
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        text-sky-600
        
    `}
    input, select{
        ${tw`
            outline-none
            md:py-2
            bg-transparent
            border-b
            
        `}
    }
`
const AccountExist = styled.div`
    ${tw`
        col-span-1
    `}
    a{
        ${tw`
            text-red-600
            ml-2
            underline
            text-sm
        `}
    }
`

const ButtonContainer = styled.div`
    ${tw`
        flex
        flex-col
        mt-3
        col-span-1
    `}
    button{
        ${tw`
            bg-sky-600
            px-10
            py-2
            text-white
            rounded-sm
            uppercase
            font-Raleway
        `}
    }
`

const SubHeader = styled.div`
    ${tw`
        col-span-1
        text-sky-600
        text-2xl
        flex
        text-center
        items-center
        md:justify-start
        justify-center
        w-full
    `}
`
const mapState = ({temp, user}) => ({
    loading: temp.loading,
    error: temp.error,
    currentUser: user.currentUser
})

const Login = () => {
    const {loading, error, currentUser} = useSelector(mapState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email:'',
        password:''
    })


    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log("Login form data", formData)
        dispatch(userAuthentication(formData))
    }
    
    useEffect(()=>{
        dispatch(setProgress(null))
        dispatch(setError(null))
    },[])

    useEffect(()=>{
        if(currentUser) {
            navigate("/home", {replace: true})
        }
    },[currentUser])

    return(
        <PageContainer>
            <FormContainer>
                <form onSubmit={handleSubmit}>
                    <RegisterForm>
                        <LogoContainer>
                            <Link to="/">eSkole</Link>
                        </LogoContainer>
                        <SubHeader>Log In to your account</SubHeader>
                        <FormField>
                            <label>Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="" 
                                autoComplete="off" 
                                required
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField>
                            <label>Password</label>
                            <input 
                                type="password" 
                                name="password"  
                                autoComplete="off" 
                                required
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField>
                            <AccountExist>
                                <span>Need an account?</span>
                                <span><Link to="/register">Create new account</Link></span>
                            </AccountExist>
                        </FormField>
                        <ButtonContainer>
                            <button type="submit">
                                {
                                    (loading === true && error?.hasError !== true) ? "Loggin in..." : "Log In"
                                }
                            </button>
                            {
                                (loading === true && error?.hasError !== true) && (
                                    <Spinner />
                                )
                            }
                        </ButtonContainer>
                    </RegisterForm>
                </form>
            </FormContainer>
        </PageContainer>
    )
}

export default Login