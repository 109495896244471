import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import dots from "../assets/dots.svg"
import SubSidebar from "./Subsidebar";
import DynamicIcon from "./DynamicReactIcon";
import { useDispatch, useSelector } from "react-redux";
import { getUserMenuList, getUserSubMenuList } from "../redux/user/user.actions";

const SidebarContainer = styled.div`
    ${tw`
        // w-[225px]
        w-[65px]
        h-[100%]
        fixed
        top-0
        left-0
        bg-violet-800
        lg:flex
        lg:flex-col
        hidden
        box-border
    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        py-3
        text-amber-400
        text-base
        font-Raleway
        font-bold
    `}
`

const MenuContainer = styled.div`
    ${tw`
        w-full
    `}
    ul{
        ${tw`
            w-full
        `}
        li{
            ${tw`
                flex
                text-center
                items-center
                justify-center
                text-white
                w-full
                hover:bg-white
                hover:text-violet-800
                box-border
                hover:border-l-4
                hover:border-amber-400
                py-3

                transition-[1.5s]

            `}
            a{
                ${tw`
                    flex
                    flex-col
                    items-center
                    text-center
                    justify-center
                    
                `}
                p{
                    ${tw`
                        text-[10px]
                    `}
                }
            }
        }
    }
`

const mapState = ({user}) => ({
    currentUser: user.currentUser,
    allowedMenu: user.allowedMenu
})

const Sidebar = () => {
    const {currentUser, allowedMenu} = useSelector(mapState)
    // console.log("Current user is:",currentUser)

    const [activeMenu, setActiveMenu] = useState(null)
    const [openSubbar, setOpenSubbar] = useState(false)
    const dispatch = useDispatch()

    const handleSubmenubar = (e) => {
        e.preventDefault()
        console.log("Id is:",e.currentTarget.id)
        setActiveMenu(e.currentTarget.id)
        
        if(!openSubbar){
            setOpenSubbar(true)
        }else{
            if(activeMenu === e.currentTarget.id){
                setOpenSubbar(false)
            }
        }

        dispatch(getUserSubMenuList(e.currentTarget.id))
    }

    useEffect(()=>{
        const data = {
            uuid:currentUser.uuid,
            role:currentUser.role,
            institutionCode: currentUser.institutionCode
        }
        dispatch(getUserMenuList(data))
    },[])

    useEffect(()=>{
        // dispatch(getUserSubMenuList(activeMenu))
    },[])

    return(
        <SidebarContainer>
            <LogoContainer>
                <span>eSkole</span>
            </LogoContainer>
            <MenuContainer id="menuContainer">
                {
                    allowedMenu && (
                        <ul>
                            <li >
                                <a href="#" id="home" onClick={handleSubmenubar} >
                                    <img  src={dots} width="20" height="20"/>
                                    <p>Home</p>
                                </a>
                            </li> 

                            {
                                allowedMenu.map((menu) => {
                                    const iconName = menu.icon
                                    const libName = iconName.substring(0,2).toLowerCase()
                                    // console.log(libName)
                                    return(
                                        <li>
                                            <a href={menu.path} id={menu.uuid} onClick={handleSubmenubar}>
                                                <DynamicIcon iconName={menu.icon} iconSize="1rem" library={libName} />
                                                <p>{menu.name}</p>
                                            </a>
                                        </li>            
                                    )
                                })
                            }      
                        </ul>        
                    )
                }
            </MenuContainer>
            <MenuContainer>

            </MenuContainer>
            {
                activeMenu&& openSubbar && (
                    <SubSidebar data={activeMenu}/>
                )
            }
        </SidebarContainer>
    )
}

export default Sidebar