import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import {FaAngleDoubleLeft} from "react-icons/fa"


const SubSidebarContainer = styled.div`
    ${tw`
        w-[200px]
        h-[100%]
        fixed
        top-0
        left-[65px]
        bg-slate-600
        lg:flex
        lg:flex-col
        hidden
        box-border
    `}
`

const SubMenuContainer = styled.ul`
    ${tw`
        w-full
    `}
    li{
        ${tw`
            text-white
            text-sm
            p-2
            hover:border-l-4
            hover:border-green-600
            hover:bg-gray-700
            transition-[2s]
            
        `}
    }
`

const CollapseContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-end
        p-2
        text-white
        cursor-pointer
    `}
`

const mapState = ({user}) => ({
    allowedSubMenu: user.allowedSubMenu
})

const SubSidebar = (props) => {
    const {allowedSubMenu} = useSelector(mapState)

    // console.log("passed props", props)
    const submenuData = props.data
    // const data = data
    return(
        <SubSidebarContainer>
            {/* Hi {submenuData} */}
            <CollapseContainer>
                <FaAngleDoubleLeft />
            </CollapseContainer>
            <SubMenuContainer>
                {
                    Array.isArray(allowedSubMenu) && allowedSubMenu.length > 0 && (
                        <ul>
                            {
                                allowedSubMenu.map((submenu) => {
                                    return(
                                        <li key={submenu.uuid}>
                                            <a href={submenu.path} id={submenu.uuid}>
                                                {submenu.name}
                                            </a>
                                        </li>            
                                    )
                                })
                            }      
                        </ul>
                    )
                }
            </SubMenuContainer>
        </SubSidebarContainer>
    )
}

export default SubSidebar