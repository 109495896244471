import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const FilterContainer = styled.div`
    ${tw`
        flex
        w-full
        items-end
        gap-2
    `}
    input{
        ${tw`
            border-b-2
            px-2
            outline-none
        `}
    }
    span{
        ${tw`
            text-sm
            text-violet-800
            uppercase
        `}
    }
`

const GlobalFilter = ({filter, setFilter}) => {
    return(
        <FilterContainer>
            <span>Search: {' '}</span>
            <input 
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Type and search..."
            />
        </FilterContainer>
    )
}

export default GlobalFilter