import { userTypes } from "./user.types";

export const userAuthentication = (data) => ({
    type: userTypes.USER_AUTHENTICATION_START,
    payload: data,
});

export const emailSignInSuccess = (user) => ({
    type: userTypes.USER_AUTHENTICATION_SUCCESS,
    payload: user,
});

export const userRegistrationWithEmail = (data) => ({
    type: userTypes.USER_REGISTRATION_WITH_EMAIL_START,
    payload: data,
});

export const setProgress = (arg) => ({
    type: userTypes.ACTION_SET_PROGRESS,
    payload: arg,
});

export const setError = (bool) => ({
    type: userTypes.ACTION_SET_ERROR,
    payload: bool,
});

export const getPageAccess = (userId) => ({
    type: userTypes.GET_USER_PAGE_ACCESS_START,
    payload: userId,
});

export const putPageAccess = (flag) => ({
    type: userTypes.GET_USER_PAGE_ACCESS_SUCCESS,
    payload: flag,
});

export const getUserMenuList = (args) => ({
    type: userTypes.GET_USER_MENU_LIST_START,
    payload: args,
});

export const putUserMenuList = (data) => ({
    type: userTypes.GET_USER_MENU_LIST_SUCCESS,
    payload: data,
});

export const getUserSubMenuList = (args) => ({
    type: userTypes.GET_USER_SUBMENU_LIST_START,
    payload: args,
});

export const putUserSubMenuList = (data) => ({
    type: userTypes.GET_USER_SUBMENU_LIST_SUCCESS,
    payload: data,
});

export const userSignOutStart = (user) => ({
    type: userTypes.USER_SIGNOUT_START,
    payload: user,
});

export const userSignOutSuccess = () => ({
    type: userTypes.USER_SIGNOUT_SUCCESS,
});
