import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import {FaUserGraduate, FaUserTie, FaClipboardList} from "react-icons/fa"
import {HiOutlineSpeakerphone, HiOutlineChartSquareBar, HiUsers} from "react-icons/hi"
import {BiBookReader, BiBus, BiReceipt} from "react-icons/bi"
import {MdOutlineOndemandVideo, MdAddCircleOutline} from "react-icons/md"
import {AiOutlineFunnelPlot, AiOutlineDollarCircle, AiOutlineSchedule, AiFillStar} from "react-icons/ai"
import {BsCalendarDate} from "react-icons/bs"

const FeatureContainer = styled.section`
    ${tw`
        // bg-violet-400
        bg-white
        md:py-10
        py-4
    `}
`

const SectionHeader = styled.div`
    ${tw`
        w-full
        lg:flex
        hidden
        lg:px-[115px]
        px-[30px]
        // text-white
        // text-violet-700
        text-sky-600
        font-medium
        items-center
        text-center
        justify-center
        text-3xl
        font-Raleway
        
    `}
`

const OfferItems = styled.div`
    ${tw`
        grid
        lg:grid-cols-3
        md:grid-cols-4
        grid-cols-2
        gap-[2px]
        lg:order-1
        order-2
        text-center
        items-center
        // h-[100px]
    `}
`

const OfferItem = styled.div`
    ${tw`
        bg-amber-400
        flex
        flex-col
        py-5
        px-2
        rounded-sm
        items-center
        text-center
        justify-center
        lg:transition-[2s]
        lg:hover:scale-110
        lg:hover:drop-shadow-xl
        h-full
        w-full  
    `}
`

const OfferName = styled.div`
    ${tw`
        // text-violet-700
        text-sky-600
        text-center
        text-base
        font-Poppins
    `}
`

const OfferDetails = styled.div`
    ${tw`
        lg:order-2
        order-1
        py-3
        m-auto
        lg:pl-16
        pl-0
    `}
`

const OfferContainer = styled.div`
    ${tw`
        grid
        lg:grid-cols-2
        grid-cols-1
        lg:px-[115px]
        md:px-[30px]
        px-[0px]
        md:mt-5
        lg:gap-5
        gap-2
        // md:mb-10
    `}
`

const OfferImage = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        // text-violet-700
        text-sky-600
        pt-5
    `}
`

const Line1 = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        items-center
        text-center
        justify-center
        font-Raleway
        // text-white
        // text-violet-700
        text-sky-600
        text-lg
    `}
    h3{
        ${tw`
            text-3xl
        `}
    }
    h3 span{
        ${tw`
            font-bold
            text-amber-400
            tracking-wider
        `}
    }
`

const Line2 = styled.div`
    ${tw`
        md:mt-10
        mt-5
        flex
        items-center
        text-center
        justify-center
        font-Raleway
        // text-white
        // text-violet-700
        text-sky-600
        md:text-lg
        text-sm
        md:gap-3
    `}
    span{
        ${tw`
            md:block
            hidden
        `}
    }
`
const Line3 = styled.div`
    ${tw`
        mt-10
        flex
        flex-col
        font-Raleway
        // text-white
        // text-violet-700
        text-sky-600
        md:text-lg
        text-sm
        md:gap-3
        px-5
    `}
    p span{
        ${tw`
            font-bold
            text-amber-400
        `}
    }
    text-align: justify;
    text-justify: inter-word;
`

const FeatureSection = () => {
    return(
        <FeatureContainer id="offering">
            <SectionHeader>
                <h3>What we are offering</h3>
            </SectionHeader>
            <OfferContainer>
                <OfferItems>
                    {/* <OfferItem>
                        <OfferName>
                            Enrollment Funnel
                        </OfferName>
                        <OfferImage>
                            <AiOutlineFunnelPlot size={50} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Marketing
                        </OfferName>
                        <OfferImage>
                            <HiOutlineSpeakerphone size={50} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            360&deg; Analytics
                        </OfferName>
                        <OfferImage>
                            <HiOutlineChartSquareBar size={50} />
                        </OfferImage>
                        
                    </OfferItem> */}
                    <OfferItem>
                        <OfferName>
                            Student Management
                        </OfferName>
                        <OfferImage>
                            <FaUserGraduate size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Staff Management
                        </OfferName>
                        <OfferImage>
                            <FaUserTie size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Vendor Management
                        </OfferName>
                        <OfferImage>
                            <HiUsers size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Library Management
                        </OfferName>
                        <OfferImage>
                            <BiBookReader size={50} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Finance Management
                        </OfferName>
                        <OfferImage>
                            <AiOutlineDollarCircle size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Reports
                        </OfferName>
                        <OfferImage>
                            <BiReceipt size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Vehicle Tracking
                        </OfferName>
                        <OfferImage>
                            <BiBus size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Digital Learning
                        </OfferName>
                        <OfferImage>
                            <MdOutlineOndemandVideo size={40} />
                        </OfferImage>
                        
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Schedule
                        </OfferName>
                        <OfferImage>
                            <AiOutlineSchedule size={40} />
                        </OfferImage>
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Attendance
                        </OfferName>
                        <OfferImage>
                            <BsCalendarDate size={40} />
                        </OfferImage>
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            Assessments
                        </OfferName>
                        <OfferImage>
                            <FaClipboardList size={40} />
                        </OfferImage>
                    </OfferItem>
                    <OfferItem>
                        <OfferName>
                            And More
                        </OfferName>
                        <OfferImage>
                            <MdAddCircleOutline size={40} />
                        </OfferImage>
                    </OfferItem>
                </OfferItems>
                <OfferDetails>
                    <Line1>
                        <h3>Introducing <span>eSkole</span></h3>
                        <p>Your Comprehensive School ERP Solution!</p>
                    </Line1>
                    <Line2>
                        <span><AiFillStar size={20} color="#fbbf24" /></span>
                        <p>Transform Your Education Institution Today!</p>
                        <span><AiFillStar size={20} color="#fbbf24"/></span>
                    </Line2>
                    <Line3>
                        <p><span>eSkole</span> is the ultimate all-in-one Educational Resource Planning system designed to cater to the 360-degree needs of your school or college. Say goodbye to administrative hassles and embrace seamless efficiency!</p>
                        <p>Streamline operations, enhance communication, and empower educators for success. Unlock a brighter future with eSkole - your partner in educational excellence!</p>   
                    </Line3>
                </OfferDetails>
            </OfferContainer>
        </FeatureContainer>
    )
}

export default FeatureSection