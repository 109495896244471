import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageAccess } from "../redux/user/user.actions";
import { useNavigate } from "react-router";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {MdAddCircle} from "react-icons/md";
import TableComponent from "../components/Table";
import { getSchoolUsers } from "../redux/public/public.actions";

const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[70px]
    `}
`

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`

const SubHeader = styled.div`
    ${tw`
        w-full
        flex
        text-center
        items-center
        justify-start
        text-violet-800
        text-lg
        border-b
        px-2
        gap-2
    `}
`

const mapState = ({user, temp}) => ({
    currentUser: user.currentUser,
    hasPageAccess: user.hasPageAccess,
    allSchoolUsers: temp.allSchoolUsers
})

const Users = ({name}) => {
    
    const {currentUser, hasPageAccess, allSchoolUsers} = useSelector(mapState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        //check access to the page
        if(currentUser){
            const arg = {
                role: currentUser.role,
                page: name
            }
            dispatch(getPageAccess(arg))
        }
    }, [currentUser])

    useEffect(()=>{
        if(!hasPageAccess){
            navigate("/unauthorized")
        }
    },[hasPageAccess])

    useEffect(()=>{
        if(currentUser){
            dispatch(getSchoolUsers(currentUser.institutionName))
        }
    },[])

    const COLUMNS = [
        // {
        //     Header: 'UID',
        //     accessor: 'uuid'
        // },
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Role',
            accessor: 'role'
        },
        {
            Header: 'Status',
            accessor: 'status'
        },
    ]

    return(
        <>
            {
                hasPageAccess === true
                ?
                    <PageContainer>
                        <Sidebar />
                        <BodyContainer>
                            <Header />
                            <BodyContentConatiner>
                                <SubHeader>
                                    <span>Users</span>
                                    <span><MdAddCircle /></span>
                                </SubHeader>
                                <br></br>
                                {
                                    (Array.isArray(allSchoolUsers) && allSchoolUsers.length > 0)
                                    ?
                                        <TableComponent cols={COLUMNS} tdata={allSchoolUsers} />
                                    : "No records found" 
                                }
                            </BodyContentConatiner>
                        </BodyContainer>
                    </PageContainer>
                :
                    hasPageAccess === null 
                    ?
                        "Loading"
                    :
                        navigate("/unauthorized")
            }
        </>
    )
}

export default Users