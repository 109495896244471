import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import styled from "styled-components";
import tw from "twin.macro";
import GlobalFilter from "./GlobalFilter";

const RTable = styled.table`
    ${tw`
        border-collapse
        w-full
        mt-5
    `}
    thead tr {
        ${tw`
            bg-slate-200
            text-violet-800
            font-Poppins
            text-sm
        `}
        th {
            ${tw`
                p-2
                text-start
            `}
        }
    }
    tbody tr {
        ${tw`
            border-b
        `}
        td {
            ${tw`
                p-2
                text-start
            `}
        }
    }
`;

const PaginationContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-end
        gap-2
        mt-5
    `}
    button {
        ${tw`
            bg-violet-800
            text-white
            px-2
            py-1
            rounded-sm
            text-sm
        `}
    }
`;

const TableComponent = ({ cols, tdata }) => {
    const columns = useMemo(() => cols, []);
    const data = useMemo(() => tdata, []);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
    } = tableInstance;

    const { globalFilter } = state;
    const { pageIndex } = state;
    return (
        <>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <RTable id="react_table" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {" "}
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </RTable>
            {canNextPage || canPreviousPage ? (
                <PaginationContainer>
                    <span>
                        Page {` `}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                        {` `}
                    </span>
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        Previous
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>
                </PaginationContainer>
            ) : null}
        </>
    );
};

export default TableComponent;
