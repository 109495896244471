import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DynamicIcon from "../components/DynamicReactIcon";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";

const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`;

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[65px]
    `}
`;

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`;

const SubHeadingContainer = styled.div`
    ${tw`
        w-full
        bg-slate-200
        p-4
        rounded-sm
        flex
        items-center
        text-center
        justify-between
        text-gray-600
        box-border
    `}
    span {
        ${tw`
            uppercase
            text-sm
            font-bold

        `}
    }
`;

const SubHeadingTitleContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-3
    `}
`;

const AddButton = styled.div`
    ${tw`
        md:flex
        items-center
        text-center
        justify-center
        text-xs
        font-medium
        uppercase
        bg-violet-600
        text-white
        py-1
        px-2
        hidden
    `}
    ::backdrop {
        background: hsl(0 0% 0% / 50%);
    }
`;

const Vendors = () => {
    return (
        <PageContainer>
            <Sidebar />
            <BodyContainer>
                <Header />
                <BodyContentConatiner>
                    <SubHeadingContainer>
                        <SubHeadingTitleContainer>
                            <DynamicIcon
                                iconName="FaUser"
                                iconSize="1rem"
                                library="fa"
                            />
                            <span>Vendors</span>
                        </SubHeadingTitleContainer>
                        <AddButton>
                            <Link to="#">Add Vendor</Link>
                        </AddButton>
                    </SubHeadingContainer>
                </BodyContentConatiner>
            </BodyContainer>
        </PageContainer>
    );
};

export default Vendors;
