import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { MdMenu, MdLogout } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { userSignOutStart } from "../redux/user/user.actions";

const HeaderContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-between
        p-2
    `}
`;

const SchoolName = styled.span`
    ${tw`
        uppercase
        text-violet-800
        text-lg
        font-Raleway
        font-bold
    `}
`;

const HeaderMenu = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-between
    `}
    ul {
        ${tw`
            lg:flex
            gap-3
            list-none
            hidden
        `}
        li {
            ${tw`
                // bg-red-300
                py-2
                px-2
                text-sm
            `}
        }
        li:last-child {
            ${tw`
                bg-violet-800
                rounded-full
                text-amber-400
            `}
        }
    }
    span {
        ${tw`
            lg:hidden
            text-violet-800
            cursor-pointer
        `}
    }
`;

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
});

const Header = () => {
    const { currentUser } = useSelector(mapState);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSignOut = (e) => {
        e.preventDefault();
        dispatch(userSignOutStart());

        navigate("/login", { replace: true });
    };
    return (
        <HeaderContainer>
            <SchoolName>{currentUser?.institutionName}</SchoolName>
            <HeaderMenu>
                <ul>
                    {/* <li>1</li>
                    <li>2</li>
                    <li>3</li> */}
                    <li>
                        <Link to="" onClick={handleSignOut}>
                            <MdLogout size={20} />
                        </Link>
                    </li>
                </ul>
                <span>
                    <MdMenu size={25} />
                </span>
            </HeaderMenu>
        </HeaderContainer>
    );
};

export default Header;
