import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import DynamicIcon from "../components/DynamicReactIcon";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import AddClass from "../components/AddClass";
import CustomModal from "../components/CustomModal";
import {
    getAllClasses,
    getAllSubjects,
    getClassSectionsOfBranch,
    getSchoolBranches,
} from "../redux/public/public.actions";
import Table from "../components/Table";

const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`;

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[65px]
    `}
`;

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`;

const SubHeadingContainer = styled.div`
    ${tw`
        w-full
        bg-slate-200
        p-4
        rounded-sm
        flex
        items-center
        text-center
        justify-between
        text-gray-600
        box-border
    `}
    span {
        ${tw`
            uppercase
            text-sm
            font-bold

        `}
    }
`;

const SubHeadingTitleContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-3
    `}
`;

const AddBranchButton = styled.div`
    ${tw`
        md:flex
        items-center
        text-center
        justify-center
        text-xs
        font-medium
        uppercase
        bg-violet-600
        text-white
        py-1
        px-2
        hidden
    `}
    ::backdrop {
        background: hsl(0 0% 0% / 50%);
    }
`;

const mapState = ({ user, temp }) => ({
    currentUser: user.currentUser,
    schoolBranches: temp.schoolBranches,
    branchClasses: temp.branchClasses,
});

const Classes = () => {
    const { currentUser, schoolBranches, branchClasses } =
        useSelector(mapState);
    const [openModal, setOpenModal] = useState(null);

    const classTableColumns = [
        {
            id: "branchClass",
            accessor: "branchClass", //normal recommended usage of an accessorKey
            Header: "Class",
        },
        {
            id: "classSection", //example of dot notation used to access nested data
            accessor: "classSection", //example of dot notation used to access nested data
            Header: "Section",
        },
        {
            id: "mediumOfInstruction", //example of dot notation used to access nested data
            accessor: "mediumOfInstruction", //example of dot notation used to access nested data
            Header: "MOI",
        },
        {
            id: "institutionBranch",
            accessor: "institutionBranch",
            Header: "Branch",
        },
        {
            id: "institutionName",
            accessor: "institutionName",
            Header: "Institution",
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getClassSectionsOfBranch(currentUser.institutionCode));
    }, []);

    const handleBranchModal = (e) => {
        e.preventDefault();
        // const userParams = {
        //     userRole: userRole,
        //     userSchoolName: currentUser.institutionName,
        //     userSchoolCode: currentUser.institutionCode,
        // };
        // dispatch(getAllSchoolsList(userParams));
        // dispatch(getAllCountries());
        // dispatch(setProgress(null));
        // dispatch(setError(null));
        dispatch(getSchoolBranches(currentUser.institutionCode));
        dispatch(getAllClasses());
        dispatch(getAllSubjects());
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    // useEffect(() => {
    //     if (openModal && loading === false && error === null) {
    //         setOpenModal(false);
    //     }
    // }, [openModal, loading, error]);

    return (
        <PageContainer>
            <Sidebar />
            <BodyContainer>
                <Header />
                <BodyContentConatiner>
                    <SubHeadingContainer>
                        <SubHeadingTitleContainer>
                            <DynamicIcon
                                iconName="FaChalkboardTeacher"
                                iconSize="1rem"
                                library="fa"
                            />
                            <span>Classes</span>
                        </SubHeadingTitleContainer>
                        <AddBranchButton>
                            <Link to="#" onClick={handleBranchModal}>
                                Add Class
                            </Link>
                        </AddBranchButton>
                    </SubHeadingContainer>
                    {branchClasses.length > 0 && (
                        <Table tdata={branchClasses} cols={classTableColumns} />
                    )}
                </BodyContentConatiner>
                {openModal && (
                    <CustomModal
                        openState={openModal}
                        onClose={handleClose}
                        title="Add New Class"
                    >
                        <AddClass />
                    </CustomModal>
                )}
            </BodyContainer>
        </PageContainer>
    );
};

export default Classes;
