import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import styled from "styled-components";
import tw from "twin.macro";
import DynamicIcon from "../components/DynamicReactIcon";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllCountries,
    getCountryStates,
    getInstitutionDetails,
    updateInstitutionDetails,
} from "../redux/public/public.actions";
import Spinner from "../components/Spinner";

const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`;

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[65px]
    `}
`;

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`;

const SubHeadingContainer = styled.div`
    ${tw`
        w-full
        bg-slate-200
        p-4
        rounded-sm
        flex
        items-center
        text-center
        justify-start
        gap-3
        text-gray-600
    `}
    span {
        ${tw`
            uppercase
            text-sm
            font-bold

        `}
    }
`;

const HeadingLevel3 = styled.div`
    ${tw`
        w-full
        lg:col-span-3
        md:col-span-2
        col-span-1
        mt-3
        text-slate-600
        font-Poppins
    `}
`;

const DetailsContainer = styled.div`
    ${tw`
        grid
        lg:grid-cols-3
        lg:px-4
        lg:gap-4
        md:grid-cols-2
        grid-cols-1
    `}
`;

const FormField = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        py-2
        text-violet-800
    `}
    span {
        ${tw`
            font-Poppins
            text-sm
        `}
    }
    input,
    select {
        ${tw`
            py-1
            border-b
            border-slate-200
            outline-none
            focus:border-b-violet-400
            text-sm
            font-medium
        `}
    }
`;

const LabelContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-1
    `}
`;

const RequiredField = styled.span`
    ${tw`
        text-red-600
        text-sm
    `}
`;

const ButtonContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-start
        mt-2
        px-4
        gap-2
    `}
    button {
        ${tw`
            uppercase
            text-xs
            font-medium
            bg-violet-600
            text-white
            hover:bg-amber-400
            hover:text-violet-800
            px-4
            py-2
            rounded-sm
        `}
    }
`;

const mapState = ({ user, temp }) => ({
    currentUser: user.currentUser,
    institutionDetails: temp.institutionDetails,
    allCountries: temp.allCountries,
    countryStates: temp.countryStates,
    loading: temp.loading,
    error: temp.error,
});

const MyOrganisation = () => {
    const {
        currentUser,
        institutionDetails,
        allCountries,
        countryStates,
        loading,
        error,
    } = useSelector(mapState);

    const [formData, setFormData] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [prevformData, setPrevFormData] = useState();
    const selectRef = useRef("");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInstitutionDetails(currentUser.institutionCode));
        dispatch(getAllCountries());
    }, []);

    useEffect(() => {
        console.log("Printing instututional details", institutionDetails);
        if (institutionDetails) {
            setFormData({
                ...formData,
                uuid: institutionDetails.uuid,
                institutionName: institutionDetails.institutionName,
                institutionType: institutionDetails.institutionType,
                institutionRegNo: institutionDetails?.institutionRegNo,
                institutionEmail: institutionDetails?.institutionEmail,
                institutionPhone: institutionDetails?.institutionPhone,
                institutionAddress: institutionDetails?.institutionAddress,
                institutionCountry: institutionDetails?.institutionCountry,
                institutionState: institutionDetails?.institutionState,
                institutionCity: institutionDetails?.institutionCity,
            });
            setSelectedCountry(institutionDetails?.institutionCountry);
        }
    }, [institutionDetails]);

    useEffect(() => {
        setPrevFormData(formData);
    }, [formData]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index];
        const option = el.getAttribute("id");

        console.log("Selected Id is:", option);

        switch (name) {
            case "institutionCountry":
                setSelectedCountry(option);
                setFormData({
                    ...formData,
                    [name]: value,
                });
                // dispatch(getCountryStates(option));
                break;
            case "institutionState":
                setFormData({
                    ...formData,
                    [name]: value,
                });
            default:
                return null;
        }
    };

    useEffect(() => {
        if (selectedCountry) {
            const countryIndex = selectRef.current.selectedIndex;
            const countryOption = selectRef.current.childNodes[countryIndex];
            const countryId = countryOption.getAttribute("id");
            dispatch(getCountryStates(countryId));
        }
    }, [selectedCountry]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form data is:", formData);
        dispatch(updateInstitutionDetails(formData));
    };

    return (
        <PageContainer>
            <Sidebar />
            <BodyContainer>
                <Header />
                <BodyContentConatiner>
                    <SubHeadingContainer>
                        <DynamicIcon
                            iconName="BsBuilding"
                            iconSize="1rem"
                            library="bs"
                        />
                        <span>My Organisation</span>
                    </SubHeadingContainer>
                    {formData && (
                        <form onSubmit={handleSubmit}>
                            <DetailsContainer>
                                <HeadingLevel3>Basic Details</HeadingLevel3>
                                <FormField>
                                    <LabelContainer>
                                        <span>Name</span>
                                    </LabelContainer>
                                    <input
                                        type="text"
                                        name="institutionName"
                                        value={formData?.institutionName}
                                        onChange={handleInputChange}
                                        required
                                        autoComplete="off"
                                    />
                                </FormField>
                                {/* <FormField>
                                    <LabelContainer>
                                        <span>Type</span>
                                        <span>
                                            <FaInfoCircle size={12} />
                                        </span>
                                    </LabelContainer>
                                    <input
                                        type="text"
                                        name="institutionType"
                                        value={formData?.institutionType}
                                        readOnly
                                        required
                                        autoComplete="off"
                                    />
                                </FormField> */}
                                <FormField>
                                    <LabelContainer>
                                        <span>Registration No</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <input
                                        type="text"
                                        name="institutionRegNo"
                                        value={formData?.institutionRegNo}
                                        onChange={handleInputChange}
                                        required
                                        autoComplete="off"
                                    />
                                </FormField>
                                <FormField>
                                    <LabelContainer>
                                        <span>Email</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <input
                                        type="email"
                                        name="institutionEmail"
                                        value={formData?.institutionEmail}
                                        onChange={handleInputChange}
                                        required
                                        autoComplete="off"
                                    />
                                </FormField>
                                <FormField>
                                    <LabelContainer>
                                        <span>Phone</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <input
                                        type="text"
                                        name="institutionPhone"
                                        value={formData?.institutionPhone}
                                        required
                                        pattern="^\d{10}$"
                                        placeholder="10 digit mobile number"
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                    />
                                </FormField>
                                <FormField>
                                    <LabelContainer>
                                        <span>Address</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <input
                                        type="text"
                                        name="institutionAddress"
                                        value={formData?.institutionAddress}
                                        onChange={handleInputChange}
                                        required
                                        autoComplete="off"
                                    />
                                </FormField>
                                <FormField>
                                    <LabelContainer>
                                        <span>Country</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <select
                                        name="institutionCountry"
                                        required
                                        value={formData?.institutionCountry}
                                        ref={selectRef}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="" disabled>
                                            Select
                                        </option>
                                        {allCountries &&
                                            allCountries.map((country) => {
                                                return (
                                                    <option
                                                        key={country.uuid}
                                                        id={country.uuid}
                                                    >
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </FormField>
                                <FormField>
                                    <LabelContainer>
                                        <span>State / Province</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <select
                                        name="institutionState"
                                        required
                                        value={formData?.institutionState}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="" disabled></option>
                                        {countryStates &&
                                            countryStates.map((state) => {
                                                return (
                                                    <option
                                                        key={state.uuid}
                                                        id={state.uuid}
                                                    >
                                                        {state.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </FormField>
                                <FormField>
                                    <LabelContainer>
                                        <span>City</span>
                                        <RequiredField>*</RequiredField>
                                    </LabelContainer>
                                    <input
                                        type="text"
                                        name="institutionCity"
                                        required
                                        value={formData?.institutionCity}
                                        onChange={handleInputChange}
                                    />
                                </FormField>
                            </DetailsContainer>
                            <ButtonContainer>
                                <button type="submit">
                                    {loading === true &&
                                    error?.hasError !== true
                                        ? "Saving"
                                        : "Save"}
                                </button>
                                {loading === true &&
                                    error?.hasError !== true && <Spinner />}
                            </ButtonContainer>
                        </form>
                    )}
                </BodyContentConatiner>
            </BodyContainer>
        </PageContainer>
    );
};

export default MyOrganisation;
