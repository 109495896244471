import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Slide1 from "../../assets/Slide1.jpg"
import Slide2 from "../../assets/Slide2.jpg"
import Slide3 from "../../assets/Slide3.jpg"

const SlideContainer = styled.section`
    ${tw`
        lg:px-[115px]
        px-[0px]
        text-sky-600

    `}
    text-align: justify;
    text-justify: inter-word;
`

const SlideItemOdd = styled.div`
    ${tw`
        w-full
        grid
        lg:grid-cols-2
        grid-cols-1
        gap-5
    `}
`

const SlideItemEven = styled.div`
    ${tw`
        w-full
        grid
        lg:grid-cols-2
        grid-cols-1
        gap-5
    `}
`

const SlideImageOdd = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        lg:order-1
        order-2
    `}
    img{
        ${tw`
            w-full
            h-full
        `}
    }
`

const SlideImageEven = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
    `}
    img{
        ${tw`
            w-full
            h-full
        `}
    }
`

const SlideDetailOdd = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-center
        lg:p-10
        p-2
        lg:order-2
        order-1
        px-[30px]
        md:text-base
        text-sm
    `}
    text-align: justify;
    text-justify: inter-word;
`

const SlideDetailEven = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-center
        lg:p-10
        p-2
        px-[30px]
        md:text-base
        text-sm
    `}
    text-align: justify;
    text-justify: inter-word;
`

const SlideSection = () => {
    return(
        <SlideContainer>
            <SlideItemOdd>
                <SlideImageOdd>
                    <img src={Slide1} alt="Enquiry and Admissions" title="Enquiry and Admissions" loading="lazy" height="100%" width="100%" />
                </SlideImageOdd>
                <SlideDetailOdd>
                    <p>Elevate your educational institute's growth with data-driven strategies. Expand reach with our 360 &deg; data analysis. Retarget potential students via remarketing, email campaigns & engaging webinars and maximize your acquisition funnel by harnessing analytics tools data-driven decisions. Personalize messaging, streamline enrollment, and emphasize your unique value proposition. Invest in team training for continual improvement. With these tactics, you can boost student reach, re-engage prospects, and refine your acquisition process, ensuring your educational institute stands out in a competitive market.</p>
                </SlideDetailOdd>
            </SlideItemOdd>
            <SlideItemEven>
                <SlideDetailEven>
                    <p>Brings students and their guardians an unmatched level of convenience. Access timetables, schedules, results, and reports from a single, user-friendly platform. Track school vehicles and your children in real-time for added safety and peace of mind.With our flexible payment options, you can make transactions effortlessly. Receive timely notifications for every important update. It is the one-stop destination for all student needs.</p>
                </SlideDetailEven>
                <SlideImageEven>
                    <img src={Slide2} alt="eSkole Features" title="eSkole Features" loading="lazy" height="100%" width="100%" />
                </SlideImageEven>
            </SlideItemEven>
            <SlideItemOdd>
                <SlideImageOdd>
                    <img src={Slide3} alt="Outcomes" title="Outcomes" loading="lazy" height="100%" width="100%" />
                </SlideImageOdd>
                <SlideDetailOdd>
                    With eSkole, you can increase the efficiency of your operation by 500%, outstanding reach to the potential students, improved parents and students experience by high quality services. A win-win both your eductaiional institution, students and their guardians 
                </SlideDetailOdd>
            </SlideItemOdd>
        </SlideContainer>
    )
}

export default SlideSection