export const publicTypes = {
    ACTION_SET_PROGRESS: "ACTION_SET_PROGRESS",
    ACTION_SET_ERROR: "ACTION_SET_ERROR",
    GET_ALL_USERS_OF_SCHOOL_START: "GET_ALL_USERS_OF_SCHOOL_START",
    GET_ALL_USERS_OF_SCHOOL_SUCCESS: "GET_ALL_USERS_OF_SCHOOL_SUCCESS",
    CREATE_SCHOOL_PROFILE_START: "CREATE_SCHOOL_PROFILE_START",
    CREATE_SCHOOL_PROFILE_SUCCESS: "CREATE_SCHOOL_PROFILE_SUCCESS",
    GET_INSTITUTION_DETAILS_START: "GET_INSTITUTION_DETAILS_START",
    GET_INSTITUTION_DETAILS_SUCCESS: "GET_INSTITUTION_DETAILS_SUCCESS",
    GET_ALL_COUNTRIES_START: "GET_ALL_COUNTRIES_START",
    GET_ALL_COUNTRIES_SUCCESS: "GET_ALL_COUNTRIES_SUCCESS",
    GET_ALL_STATES_OF_COUNTRY_START: "GET_ALL_STATES_OF_COUNTRY_START",
    GET_ALL_STATES_OF_COUNTRY_SUCCESS: "GET_ALL_STATES_OF_COUNTRY_SUCCESS",
    UPDATE_INSTITUTION_DETAILS_START: "UPDATE_INSTITUTION_DETAILS_START",
    UPDATE_INSTITUTION_DETAILS_SUCCESS: "UPDATE_INSTITUTION_DETAILS_SUCCESS",
    GET_LIST_OF_ALL_SCHOOLS_START: "GET_LIST_OF_ALL_SCHOOLS_START",
    GET_LIST_OF_ALL_SCHOOLS_SUCCESS: "GET_LIST_OF_ALL_SCHOOLS_SUCCESS",
    ADD_NEW_SCHOOL_BRANCH_START: "ADD_NEW_SCHOOL_BRANCH_START",
    ADD_NEW_SCHOOL_BRANCH_SUCCESS: "ADD_NEW_SCHOOL_BRANCH_SUCCESS",
    GET_SCHOOL_BRANCHES_START: "GET_SCHOOL_BRANCHES_START",
    GET_SCHOOL_BRANCHES_SUCCESS: "GET_SCHOOL_BRANCHES_SUCCESS",
    GET_ALL_CLASSES_START: "GET_ALL_CLASSES_START",
    GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_SUCCESS",
    VALIDATE_CLASS_SECTION_START: "VALIDATE_CLASS_SECTION_START",
    GET_CLASS_SECTIONS_OF_BRANCH_START: "GET_CLASS_SECTIONS_OF_BRANCH_START",
    GET_CLASS_SECTIONS_OF_BRANCH_SUCCESS:
        "GET_CLASS_SECTIONS_OF_BRANCH_SUCCESS",
    GET_ALL_SUBJECTS_START: "GET_ALL_SUBJECTS_START",
    GET_ALL_SUBJECTS_SUCCESS: "GET_ALL_SUBJECTS_SUCCESS",
};
