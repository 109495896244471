import { publicTypes } from "./public.types";

const INITIAL_STATE = {
    loading: null,
    error: null,
    allSchoolUsers: [],
    institutionDetails: null,
    allCountries: [],
    countryStates: [],
    allSchools: [],
    schoolBranches: [],
    allClasses: [],
    nextSection: null,
    branchClasses: [],
    allSubjects: [],
};

const publicReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case publicTypes.ACTION_SET_PROGRESS:
            return {
                ...state,
                loading: action.payload,
            };
        case publicTypes.ACTION_SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case publicTypes.GET_ALL_USERS_OF_SCHOOL_SUCCESS:
            return {
                ...state,
                allSchoolUsers: action.payload,
            };
        case publicTypes.GET_INSTITUTION_DETAILS_SUCCESS:
            return {
                ...state,
                institutionDetails: action.payload,
            };
        case publicTypes.GET_ALL_COUNTRIES_SUCCESS:
            return {
                ...state,
                allCountries: action.payload,
            };
        case publicTypes.GET_ALL_STATES_OF_COUNTRY_SUCCESS:
            return {
                ...state,
                countryStates: action.payload,
            };
        case publicTypes.GET_LIST_OF_ALL_SCHOOLS_SUCCESS:
            return {
                ...state,
                allSchools: action.payload,
            };
        case publicTypes.GET_SCHOOL_BRANCHES_SUCCESS:
            return {
                ...state,
                schoolBranches: action.payload,
            };
        case publicTypes.GET_ALL_CLASSES_SUCCESS:
            return {
                ...state,
                allClasses: action.payload,
            };
        case publicTypes.GET_NEXT_VALID_CLASS_SECTION_SUCCESS:
            return {
                ...state,
                nextSection: action.payload,
            };
        case publicTypes.GET_CLASS_SECTIONS_OF_BRANCH_SUCCESS:
            return {
                ...state,
                branchClasses: action.payload,
            };
        case publicTypes.GET_ALL_SUBJECTS_SUCCESS:
            return {
                ...state,
                allSubjects: action.payload,
            };
        default:
            return state;
    }
};

export default publicReducer;
