import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist"
import createSagaMiddle from "redux-saga"
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
import {logger} from "redux-logger"
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const persistConfig = {
    key:'root',
    storage,
    blacklist: [
        'temp'
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = new createSagaMiddle()
const middlewares = [sagaMiddleware,thunk, logger]

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store);
export default store;
