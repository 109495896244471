import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageAccess } from "../redux/user/user.actions";
import { useNavigate } from "react-router";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DynamicIcon from "../components/DynamicReactIcon";

const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[70px]
    `}
`

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`

const WelcomeContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        items-start
        text-center
        justify-start
    `}
`

const WelcomeText = styled.span`
    ${tw`
        font-Raleway
        text-2xl
        font-medium
        text-violet-700
    `}
`

const UserRole = styled.span`
    ${tw`
        bg-violet-700
        text-amber-400
        uppercase
        px-2
        py-[2px]
        text-[10px]
        rounded-lg
    `}
`

const ModuleContainer = styled.div`
    ${tw`
        w-full
        grid
        grid-cols-8
        p-2
        px-4
    `}
`

const AdminDashboard = styled.div`
    ${tw`
        w-full
        py-4
        grid
        lg:grid-cols-4
        md:grid-cols-1
        grid-cols-1
    `}
`

const AdminKPI = styled.div`
    ${tw`
        grid
        lg:grid-cols-2
        md:grid-cols-4
        grid-cols-1
        gap-1
        w-full
    `}
    span{
        ${tw`
            border-l-4
            border-violet-800
            bg-violet-200
            flex
            flex-col
            p-2
            text-violet-800
            w-full
        `}
        h1{
            ${tw`
                text-2xl
                
            `}
        }
        h3{
            ${tw`
                text-sm
            `}
        }
    }
`

const TempNotice = styled.div`
    ${tw`
        w-full
        text-center
        items-center
        flex
        justify-center
        py-[200px]
        text-slate-300
        text-xl
    `}
`

const mapState = ({user}) => ({
    currentUser: user.currentUser,
    hasPageAccess: user.hasPageAccess
})

const Home = ({name}) => {
    
    const {currentUser, hasPageAccess} = useSelector(mapState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        //check access to the page
        if(currentUser){
            const arg = {
                role: currentUser.role,
                page: name
            }
            dispatch(getPageAccess(arg))
        }
    }, [])

    useEffect(()=>{
        console.log("Displaying User access:", hasPageAccess)
    },[hasPageAccess])
    // useEffect(()=>{
        
    //     if(currentUser && !hasPageAccess){
    //         navigate("/unauthorized")
    //     }
    // },[])

    return(
        <>
            {
                hasPageAccess === true
                ?
                    <PageContainer>
                        <Sidebar />
                        <BodyContainer>
                            <Header />
                            <BodyContentConatiner>
                                <WelcomeContainer>
                                    <WelcomeText>
                                        Welcome, { currentUser?.name}
                                    </WelcomeText>
                                    <UserRole>
                                        {currentUser?.role}
                                    </UserRole>
                                </WelcomeContainer>
                                <TempNotice>
                                    <DynamicIcon />
                                    <span>Details will be populated once you have enough data</span>
                                </TempNotice>
                                {/* <AdminDashboard>
                                    <AdminKPI>
                                        <span>
                                            <h1>23,000</h1>
                                            <h3>Students</h3>
                                        </span>
                                        <span>
                                            <h1>547</h1>
                                            <h3>New Admissions 2023</h3>
                                        </span>
                                        <span>
                                            <h1>3.4 M</h1>
                                            <h3>Fees Collected</h3>
                                        </span>
                                        <span>
                                            <h1>0.5 M</h1>
                                            <h3>Fees Pending</h3>
                                        </span>
                                    </AdminKP
                                </AdminDashboard> */}
                            </BodyContentConatiner>
                        </BodyContainer>
                    </PageContainer>
                :
                    hasPageAccess === null 
                    ?
                        "Loading"
                    :
                        navigate("/unauthorized")

            }
        </>
    )
}

export default Home