import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import HeroSection from "../components/Landing/HeroSection";
import FeatureSection from "../components/Landing/FeatureSection";
import FooterSection from "../components/Landing/FooterSection";
import SlideSection from "../components/Landing/SlideSection";
import PricingSection from "../components/Landing/PricingSection";
import EnquirySection from "../components/Landing/EnquirySection";

const PageContainer = styled.div`
    ${tw`
        w-full 
    `}
`;

const Landing = () => {
    return (
        <PageContainer>
            <HeroSection />
            <FeatureSection />
            <SlideSection />
            {/* <PricingSection/> */}
            <EnquirySection />
            <FooterSection />
        </PageContainer>
    );
};

export default Landing;
