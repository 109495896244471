import { userTypes } from "./user.types";

const INITIAL_STATE = {
    currentUser: null,
    hasPageAccess: null,
    allowedMenu: [],
    allowedSubMenu: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userTypes.USER_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
            };
        case userTypes.GET_USER_PAGE_ACCESS_SUCCESS:
            return {
                ...state,
                hasPageAccess: action.payload,
            };
        case userTypes.GET_USER_MENU_LIST_SUCCESS:
            return {
                ...state,
                allowedMenu: action.payload,
            };
        case userTypes.GET_USER_SUBMENU_LIST_SUCCESS:
            return {
                ...state,
                allowedSubMenu: action.payload,
            };
        case userTypes.USER_SIGNOUT_SUCCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default userReducer;
