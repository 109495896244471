import {
    addDoc,
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { db } from "../../firebase/utility";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { publicTypes } from "./public.types";
import {
    putAllClasses,
    putAllCountries,
    putAllSchools,
    putAllSubjects,
    putClassSectionsOfBranch,
    putCountryStates,
    putInstitutionDetails,
    putSchoolBranches,
    putSchoolUsers,
    setError,
    setProgress,
} from "./public.actions";
import { v4 as uuid } from "uuid";

//=============================================================================
//1. GET ALL USERS OF SCHOOL - START
//=============================================================================
export function* processAllSchoolusers(usrs) {
    const usrArr = [];
    usrs.forEach((usr) => {
        console.log("User is", usr);
        const usrObj = {
            uuid: usr.doc.data.value.mapValue.fields.uuid.stringValue,
            name: usr.doc.data.value.mapValue.fields.name.stringValue,
            email: usr.doc.data.value.mapValue.fields.email.stringValue,
            role: usr.doc.data.value.mapValue.fields.role.stringValue,
            status: usr.doc.data.value.mapValue.fields.status.stringValue,
        };

        usrArr.push(usrObj);
    });
    return usrArr;
}

export function* allSchoolUsers({ payload }) {
    console.log("getting users of school", payload);
    try {
        const userRef = collection(db, "users");
        const qry = query(userRef, where("institutionName", "==", payload));
        const { _snapshot } = yield getDocs(qry);
        const users = _snapshot.docChanges;
        const processedUsers = yield processAllSchoolusers(users);
        console.log("Processed users", processedUsers);
        yield put(putSchoolUsers(processedUsers));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetAllUsersOfSchoolStart() {
    yield takeLatest(publicTypes.GET_ALL_USERS_OF_SCHOOL_START, allSchoolUsers);
}
//=============================================================================
//1. GET ALL USERS OF SCHOOL - START
//=============================================================================

//=============================================================================
//2. CREATE SCHOOL PROFILE WHEN USER REGISTERS - START
//=============================================================================
export function* schoolProfile({ payload, schoolCode }) {
    console.log("creating school profile", payload);
    yield put(setProgress(true));

    yield put(setError(null));
    try {
        const schoolRef = collection(db, "schoolProfile");
        yield addDoc(schoolRef, {
            uuid: payload.schoolCode,
            institutionName: payload.payload.institutionName,
            institutionType: payload.payload.institutionType,
            institutionRegNo: "",
            institutionEmail: "",
            institutionPhone: payload.payload.phone,
            institutionAddress: "",
            institutionCountry: "",
            institutionState: "",
            institutionCity: "",
            status: "active",
        });

        yield put(setError(null));
    } catch (error) {
        yield put(setError(error.code));
    }

    yield put(setProgress(false));
}

export function* onCreateSchoolProfileStart() {
    yield takeLatest(publicTypes.CREATE_SCHOOL_PROFILE_START, schoolProfile);
}
//=============================================================================
//2. CREATE SCHOOL PROFILE WHEN USER REGISTERS - END
//=============================================================================

//=============================================================================
//3. GET INSTITUTION DETAILS - START
//=============================================================================
export function* processInstitutionData(schoolRawData) {
    let schoolData = null;
    if (Array.isArray(schoolRawData) && schoolRawData.length > 0) {
        const schoolRec = schoolRawData[0];
        schoolData = {
            uuid: schoolRec.doc.data.value.mapValue.fields.uuid.stringValue,
            institutionName:
                schoolRec.doc.data.value.mapValue.fields.institutionName
                    .stringValue,
            institutionType:
                schoolRec.doc.data.value.mapValue.fields.institutionType
                    .stringValue,
            institutionEmail:
                schoolRec.doc.data.value.mapValue.fields.institutionEmail
                    .stringValue,
            institutionAddress:
                schoolRec.doc.data.value.mapValue.fields.institutionAddress
                    .stringValue,
            institutionCity:
                schoolRec.doc.data.value.mapValue.fields.institutionCity
                    .stringValue,
            institutionState:
                schoolRec.doc.data.value.mapValue.fields.institutionState
                    .stringValue,
            institutionCountry:
                schoolRec.doc.data.value.mapValue.fields.institutionCountry
                    .stringValue,
            institutionPhone:
                schoolRec.doc.data.value.mapValue.fields.institutionPhone
                    .stringValue,
            institutionRegNo:
                schoolRec.doc.data.value.mapValue.fields.institutionRegNo
                    .stringValue,
        };
    }
    return schoolData;
}

export function* institutionDetails({ payload }) {
    try {
        const schoolRef = collection(db, "schoolProfile");
        const qry = query(schoolRef, where("uuid", "==", payload));
        const { _snapshot } = yield getDocs(qry);
        const schoolRec = _snapshot.docChanges;

        const processedInstitutionData = yield processInstitutionData(
            schoolRec
        );
        yield put(putInstitutionDetails(processedInstitutionData));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetInstitutionDetailStart() {
    yield takeLatest(
        publicTypes.GET_INSTITUTION_DETAILS_START,
        institutionDetails
    );
}
//=============================================================================
//3. GET INSTITUTION DETAILS - END
//=============================================================================

//=============================================================================
//4. GET ALL COUNTRIES - START
//=============================================================================
export function* processCountryData(countryRawData) {
    const countryArr = [];
    if (Array.isArray(countryRawData) && countryRawData.length > 0) {
        countryRawData.forEach((country) => {
            const countryData = {
                uuid: country.doc.data.value.mapValue.fields.uuid.stringValue,
                name: country.doc.data.value.mapValue.fields.name.stringValue,
            };
            countryArr.push(countryData);
        });
    }
    return countryArr;
}
export function* getCountryList() {
    try {
        const countryRef = collection(db, "countries");
        const { _snapshot } = yield getDocs(countryRef);
        const countryRecs = _snapshot.docChanges;
        const processedCountries = yield processCountryData(countryRecs);

        yield put(putAllCountries(processedCountries));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetAllCountriesStart() {
    yield takeLatest(publicTypes.GET_ALL_COUNTRIES_START, getCountryList);
}

//=============================================================================
//4. GET ALL COUNTRIES - END
//=============================================================================

//=============================================================================
//5. GET ALL STATES OF THE SELECTED COUNTRY - START
//=============================================================================
export function* processStateList(rawStateData) {
    let stateArr = [];
    if (Array.isArray(rawStateData) && rawStateData.length > 0) {
        rawStateData.forEach((state) => {
            const stateData = {
                uuid: state.doc.data.value.mapValue.fields.uuid.stringValue,
                name: state.doc.data.value.mapValue.fields.name.stringValue,
            };
            stateArr.push(stateData);
        });
    }

    return stateArr;
}
export function* fetchStatesOfCountry({ payload }) {
    // console.log("Fetch states for the country:", payload);
    try {
        const statesRef = collection(db, "states");
        const qry = query(statesRef, where("countryId", "==", payload));
        const { _snapshot } = yield getDocs(qry);
        const stateRecs = _snapshot.docChanges;
        const processedStates = yield processStateList(stateRecs);

        yield put(putCountryStates(processedStates));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetAllStatesOfCountryStart() {
    yield takeLatest(
        publicTypes.GET_ALL_STATES_OF_COUNTRY_START,
        fetchStatesOfCountry
    );
}
//=============================================================================
//5. GET ALL STATES OF THE SELECTED COUNTRY - END
//=============================================================================

//=============================================================================
//6. UPDATE SCHOOL DETAILS - START
//=============================================================================
export function* schoolDetailsUpdate({ payload }) {
    yield put(setProgress(true));

    yield put(setError(null));
    try {
        const schoolRef = collection(db, "schoolProfile");
        const qry = query(schoolRef, where("uuid", "==", payload.uuid));
        const { _snapshot } = yield getDocs(qry);
        const schoolRecId = _snapshot.docChanges[0].doc.key.path.segments[6];
        console.log("School Id to be updated", schoolRecId);

        try {
            const updateSchoolRef = doc(db, "schoolProfile", schoolRecId);
            yield updateDoc(updateSchoolRef, {
                institutionName: payload.institutionName,
                institutionRegNo: payload.institutionRegNo,
                institutionEmail: payload.institutionEmail,
                institutionPhone: payload.institutionPhone,
                institutionAddress: payload.institutionAddress,
                institutionCountry: payload.institutionCountry,
                institutionState: payload.institutionState,
                institutionCity: payload.institutionCity,
            });

            yield put(setError(null));
        } catch (error) {
            console.log(error);
            yield put(setError(error.code));
        }
    } catch (error) {
        console.log(error);
        yield put(setError(error.code));
    }

    yield put(setProgress(false));
}

export function* onUpdateSchoolDetailsStart() {
    yield takeLatest(
        publicTypes.UPDATE_INSTITUTION_DETAILS_START,
        schoolDetailsUpdate
    );
}
//=============================================================================
//6. UPDATE SCHOOL DETAILS - END
//=============================================================================

//=============================================================================
//7. GET LIST OF ALL SCHOOLS - START
//=============================================================================
export function* allSchools({ payload }) {
    console.log("Getting school list", payload);
    let schoolArr = [];
    switch (payload.role) {
        case "su":
            console.log("User is SU, bring the entire list");
            break;
        default:
            const dt = {
                institutionName: payload.userSchoolName,
                institutionCode: payload.userSchoolCode,
            };
            schoolArr.push(dt);

            yield put(putAllSchools(schoolArr));
    }
}

export function* onGetListOfAllSchoolsStart() {
    yield takeLatest(publicTypes.GET_LIST_OF_ALL_SCHOOLS_START, allSchools);
}
//=============================================================================
//7. GET LIST OF ALL SCHOOLS - END
//=============================================================================

//=============================================================================
//8. ADD NEW SCHOOL BRANCH - START
//=============================================================================
export function* addBranch({ payload }) {
    console.log("Add branch data", payload);
    yield put(setProgress(true));
    yield put(setError(null));

    try {
        const branchRef = collection(db, "branches");
        yield addDoc(branchRef, {
            ...payload,
            uuid: uuid(),
        });
    } catch (error) {
        yield put(setError(error));
        console.log(error);
    }

    yield put(setProgress(false));
}

export function* onAddNewSchoolBranchStart() {
    yield takeLatest(publicTypes.ADD_NEW_SCHOOL_BRANCH_START, addBranch);
}
//=============================================================================
//8. ADD NEW SCHOOL BRANCH - END
//=============================================================================
//=============================================================================
//9. GET LIST OF BRANCHES FOF A SCHOOL - START
//=============================================================================
export function* processBranchData(rawBranchList) {
    let branchArr = [];
    if (Array.isArray(rawBranchList) && rawBranchList.length > 0) {
        rawBranchList.forEach((branch) => {
            const dt = {
                uuid: branch.doc.data.value.mapValue.fields.uuid.stringValue,
                branchName:
                    branch.doc.data.value.mapValue.fields.branchName
                        .stringValue,
                branchBoard:
                    branch.doc.data.value.mapValue.fields.branchBoard
                        .stringValue,
                mediumOfInstruction:
                    branch.doc.data.value.mapValue.fields.mediumOfInstruction
                        .stringValue,
                branchCity:
                    branch.doc.data.value.mapValue.fields.branchCity
                        .stringValue,
                branchState:
                    branch.doc.data.value.mapValue.fields.branchState
                        .stringValue,
            };
            branchArr.push(dt);
        });
    }
    return branchArr;
}

export function* getSchBranches({ payload }) {
    console.log("Getting branches of school", payload);
    try {
        const branchRef = collection(db, "branches");
        const qry = query(
            branchRef,
            where("branchInstitution.institutionCode", "==", payload)
        );
        const { _snapshot } = yield getDocs(qry);
        const branchRecs = _snapshot.docChanges;

        const processedBranchList = yield processBranchData(branchRecs);
        // console.log("Branch list", processedBranchList);
        yield put(putSchoolBranches(processedBranchList));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetAllBranchesOfSchoolStart() {
    yield takeLatest(publicTypes.GET_SCHOOL_BRANCHES_START, getSchBranches);
}
//=============================================================================
//9.  GET LIST OF BRANCHES FOF A SCHOOL - END
//=============================================================================

//=============================================================================
//10.  GET LIST OF ALL CLASSES FROM MASTER - START
//=============================================================================
export function* processClassData(rawClassList) {
    const classArr = [];
    if (Array.isArray(rawClassList) && rawClassList.length > 0) {
        rawClassList.forEach((cls) => {
            const dt = {
                name: cls.doc.data.value.mapValue.fields.name.stringValue,
            };
            classArr.push(dt);
        });
    }
    return classArr;
}

export function* getAllClassList() {
    try {
        const classMasterRef = collection(db, "master_class");
        const { _snapshot } = yield getDocs(classMasterRef);
        const classRecs = _snapshot.docChanges;
        const processedClasses = yield processClassData(classRecs);
        yield put(putAllClasses(processedClasses));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetAllClassesStart() {
    yield takeLatest(publicTypes.GET_ALL_CLASSES_START, getAllClassList);
}
//=============================================================================
//10.  GET LIST OF ALL CLASSES FROM MASTER - END
//=============================================================================

//=============================================================================
//11. VALIDATE CLASS AND SECTION - START
//=============================================================================
export function* saveClassSectionRecord(data) {
    console.log("Saving class data", data);
    try {
        const classRef = collection(db, "classes");
        yield addDoc(classRef, {
            institutionCode: data.vInstitution,
            institutionBranch: data.vBranch,
            branchClass: data.vClass,
            classSection: data.vSection,
            mediumOfInstruction: data.vMediumOfInstruction,
            classMandatorySubjects: data.vMandatorySubjects,
            classOptionalSubjects: data.vOptionalSubjects,
        });
    } catch (error) {
        console.log(error.code);
        yield put(setError(error.code));
    }
}

export function* validateSection({ payload }) {
    console.log("Payload is:", payload);

    yield put(setError(null));
    yield put(setProgress(true));
    try {
        const classRef = collection(db, "classes");
        const qry = query(
            classRef,
            where("institutionCode", "==", payload.vInstitution),
            where("institutionBranch", "==", payload.vBranch),
            where("branchClass", "==", payload.vClass),
            where("classSection", "==", payload.vSection)
        );

        const { _snapshot } = yield getDocs(qry);
        const classRecs = _snapshot.docChanges.length;
        if (classRecs === 0) {
            yield saveClassSectionRecord(payload);
        } else {
            yield put(setError("Class/section already exists."));
        }
    } catch (error) {
        console.log(error);
        yield put(setError(error.code));
    }

    yield put(setProgress(false));
}

export function* onValidateClassSectionStart() {
    yield takeLatest(publicTypes.VALIDATE_CLASS_SECTION_START, validateSection);
}
//=============================================================================
//11.  VALIDATE CLASS AND SECTION - END
//=============================================================================
//=============================================================================
//12.  GET ALL CLASSES AND SECTIONS OF THE BRANCH - START
//=============================================================================
export function* processClassSections(rawClassList) {
    let classArr = [];
    rawClassList.forEach((cls) => {
        const dt = {
            branchClass:
                cls.doc.data.value.mapValue.fields.branchClass.stringValue,
            classSection:
                cls.doc.data.value.mapValue.fields.classSection.stringValue,
            institutionBranch:
                cls.doc.data.value.mapValue.fields.institutionBranch
                    .stringValue,
            mediumOfInstruction:
                cls.doc.data.value.mapValue.fields.mediumOfInstruction
                    .stringValue,
        };
        classArr.push(dt);
    });
    return classArr;
}
export function* getClassSectionList({ payload }) {
    try {
        const classRef = collection(db, "classes");
        const qry = query(classRef, where("institutionCode", "==", payload));

        const { _snapshot } = yield getDocs(qry);
        const classRecs = _snapshot.docChanges;
        const processedClassesList = yield processClassSections(classRecs);
        yield put(putClassSectionsOfBranch(processedClassesList));
    } catch (error) {
        console.log(error.code);
    }
}
export function* onGetAllClassSectionOfBranchStart() {
    yield takeLatest(
        publicTypes.GET_CLASS_SECTIONS_OF_BRANCH_START,
        getClassSectionList
    );
}
//=============================================================================
//12.  GET ALL CLASSES AND SECTIONS OF THE BRANCH - END
//=============================================================================

//=============================================================================
//13.  GET LIST OF ALL SUBJECTS FROM MASTER - START
//=============================================================================
export function* getSubjects() {
    console.log("Getting all subjects");
    try {
        const subjectsRef = collection(db, "master_subjects");
        const { _snapshot } = yield getDocs(subjectsRef);
        const subjectRecs =
            _snapshot.docChanges[0].doc.data.value.mapValue.fields.subjects
                .arrayValue.values;
        yield put(putAllSubjects(subjectRecs));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetAllSubjectsStart() {
    yield takeLatest(publicTypes.GET_ALL_CLASSES_START, getSubjects);
}
//=============================================================================
//12.  GET LIST OF ALL SUBJECTS FROM MASTER - END
//=============================================================================

export default function* publicSagas() {
    yield all([
        call(onGetAllUsersOfSchoolStart),
        call(onCreateSchoolProfileStart),
        call(onGetInstitutionDetailStart),
        call(onGetAllCountriesStart),
        call(onGetAllStatesOfCountryStart),
        call(onUpdateSchoolDetailsStart),
        call(onGetListOfAllSchoolsStart),
        call(onAddNewSchoolBranchStart),
        call(onGetAllBranchesOfSchoolStart),
        call(onGetAllClassesStart),
        call(onValidateClassSectionStart),
        call(onGetAllClassSectionOfBranchStart),
        call(onGetAllSubjectsStart),
    ]);
}
