import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { addQuery } from "../../firebase/utility"

const EnquiryContainer = styled.div`
    ${tw`
        w-full
        grid
        lg:grid-cols-2
        grid-cols-1
        // bg-amber-400
        lg:px-[115px]
        px-[30px]
        py-5
    `}
`

const FormContainer = styled.div`
    ${tw`
        order-2 
        lg:order-1
    `}
`

const ContactHeader = styled.div`
    ${tw`
        w-full
        py-3
        md:text-2xl
        text-xl
        text-sky-600
        flex
        lg:justify-start
        md:justify-center
        md:mt-5
    `}
`

const ContactForm = styled.div`
    ${tw`
        w-full
        mt-5
        grid
        grid-cols-1
        md:grid-cols-2
        gap-2
        m-auto
    `}
`

const ButtonContainer = styled.div`
    ${tw`
        flex
        mt-8
    `}
    button{
        ${tw`
            bg-sky-600
            px-10
            py-2
            text-white
            rounded-sm
            uppercase
            font-Raleway
        `}
    }
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        text-sky-600
        mb-1
        
    `}
    input{
        ${tw`
            outline-none
            md:py-5
            py-2
            bg-transparent
            border-b
            
            
        `}
    }
`
const ContactQuote = styled.div`
    ${tw`
        col-span-1
        hidden
        md:flex
        md:flex-col
        items-center
        text-center
        justify-center
        text-sky-600
        font-bold
        lg:px-5
        lg:text-3xl
        md:text-2xl
        text-xl
        order-1
        lg:order-2
        border-b-2
        border-dashed
        border-white
        lg:border-0
        pb-10
    `}
    span{
        ${tw`
            text-amber-600
        `}
    }
`

const Msg = styled.p`
    ${tw`
        text-sm
        italic
        text-amber-400

    `}
`

const EnquirySection = () => {
    const [formData, setFormData] = useState({
        prospect:'',
        institution:'',
        studentCount:'',
        email:'',
        phone:'',
        message:''
    })
    const [qryMsg, setQueryMsg] = useState(null)
    const [qryStatus, setQueryStutus] = useState(null)

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Data", formData)
        addQuery(formData)
            .then((val) => {
                // console.log("Returned value", val)
                setQueryMsg("Your query has been submitted, we will get back to you shortly")
                setQueryStutus(true)
            })
            .catch((err)=> {
                // console.log(err)
                setQueryMsg("Something went wrong, could not submit your query")
                setQueryStutus(false)
            })
    }

    useEffect(()=>{
        if(qryStatus){
            setTimeout(()=>{
                setQueryMsg(null)
            },10000)
        }
    },[qryMsg, qryStatus])

    return(
        <EnquiryContainer id="enquiry">
            <FormContainer>
                <form onSubmit={handleSubmit}>
                    <ContactHeader>
                        <span>Tell us about your institution</span>
                    </ContactHeader>
                    <ContactForm>
                        <FormField className="col-span-1 md:col-span-2">
                            <label>Your name</label>
                            <input 
                                type="text" 
                                name="prospect" 
                                placeholder="John Smith" 
                                autoComplete="off" 
                                required
                                value={formData.prospect}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Institution Name</label>
                            <input 
                                type="text" 
                                name="institution" 
                                placeholder="Your Institution" 
                                autoComplete="off" 
                                required
                                value={formData.institution}
                                onChange={handleInputChange} 
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>No. of Students</label>
                            <input 
                                type="number" 
                                name="studentCount" 
                                placeholder="Eg. 5300" 
                                autoComplete="off" 
                                required
                                value={formData.studentCount}
                                onChange={handleInputChange} 
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Your Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Your Email" 
                                autoComplete="off" 
                                required
                                value={formData.email}
                                onChange={handleInputChange} 
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Phone Number</label>
                            <input 
                                type="text" 
                                name="phone" 
                                placeholder="+1 1234" 
                                autoComplete="off" 
                                required
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField className="col-span-1 md:col-span-3">
                            <label>Your Message</label>
                            <input 
                                type="text" 
                                name="message" 
                                placeholder="Describe your project" 
                                autoComplete="off"
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </FormField>
                    </ContactForm>
                    <ButtonContainer>
                        <button type="submit">Submit</button>
                    </ButtonContainer>
                    {
                        qryMsg && qryStatus && (
                            <Msg className="text-sm  pt-2 italic text-green-400">{qryMsg}</Msg>
                        )
                    }
                </form>
            </FormContainer>
            <ContactQuote>
                <p>Request a free demo today</p>
            </ContactQuote>
        </EnquiryContainer>
    )
}

export default EnquirySection