import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const RouteShield = ({ name }) => {
    const { currentUser } = useSelector(mapState)
    const [isLoggedIn, setIsLoggedIn] = useState(null)
    const navigate = useNavigate()

    useEffect(()=>{
        if(!currentUser){
            navigate("/login", {replace: true})
        }
    },[currentUser])

    return (
        <Outlet />
    )
}

export default RouteShield