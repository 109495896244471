export const userTypes = {
    USER_AUTHENTICATION_START: "USER_AUTHENTICATION_START",
    USER_AUTHENTICATION_SUCCESS: "USER_AUTHENTICATION_SUCCESS",
    USER_REGISTRATION_WITH_EMAIL_START: "USER_REGISTRATION_WITH_EMAIL_START",
    USER_REGISTRATION_WITH_EMAIL_SUCCESS:
        "USER_REGISTRATION_WITH_EMAIL_SUCCESS",
    ACTION_SET_PROGRESS: "ACTION_SET_PROGRESS",
    ACTION_SET_ERROR: "ACTION_SET_ERROR",
    GET_USER_PAGE_ACCESS_START: "GET_USER_PAGE_ACCESS_START",
    GET_USER_PAGE_ACCESS_SUCCESS: "GET_USER_PAGE_ACCESS_SUCCESS",
    GET_USER_MENU_LIST_START: "GET_USER_MENU_LIST_START",
    GET_USER_MENU_LIST_SUCCESS: "GET_USER_MENU_LIST_SUCCESS",
    GET_USER_SUBMENU_LIST_START: "GET_USER_SUBMENU_LIST_START",
    GET_USER_SUBMENU_LIST_SUCCESS: "GET_USER_SUBMENU_LIST_SUCCESS",
    USER_SIGNOUT_START: "USER_SIGNOUT_START",
    USER_SIGNOUT_SUCCESS: "USER_SIGNOUT_SUCCESS",
};
