import React from "react";
import { ThreeDots } from  'react-loader-spinner'

const Spinner = (props) => {
    return(
        <ThreeDots 
            height="40" 
            width="40" 
            radius="9"
            color="#0284c7" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
    )
}

export default Spinner