import React from "react";
import Select from "react-select";
import styled from "styled-components";
import tw from "twin.macro";

const FieldContainer = styled.div`
    ${tw`
        lg:px-4
        lg:gap-5
        mt-2
        text-violet-800
    `}
    span {
        ${tw`
            font-Poppins
            text-sm
        `}
    }
`;

const SubjectsContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        py-2
        text-violet-800
    `}
    span {
        ${tw`
            font-Poppins
            text-sm
        `}
    }
    input,
    select {
        ${tw`
            py-1
            border-b
            border-slate-200
            outline-none
            focus:border-b-violet-400
            text-sm
            font-medium
        `}
    }
`;

const LabelContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-1
    `}
`;

const RequiredField = styled.span`
    ${tw`
        text-red-600
        text-sm
    `}
`;

const ReactSelect = ({ labelText, required, optns, handleMultiChange }) => {
    const label = labelText;
    let menuOptions = [];

    const processOptionsData = (rawOptions) => {
        let optArr = [];
        rawOptions.forEach((opt) => {
            const dt = {
                value: opt.stringValue,
                label: opt.stringValue,
                mandatory: null,
            };
            menuOptions.push(dt);
        });
        return menuOptions;
    };

    processOptionsData(optns);

    const handleChange = (selectedSubj) => {
        handleMultiChange(selectedSubj);
    };

    return (
        <FieldContainer>
            <LabelContainer>
                <span>{label}</span>
                {required && <RequiredField>*</RequiredField>}
            </LabelContainer>
            <Select
                options={menuOptions}
                required={required | false}
                isMulti={true}
                onChange={handleChange}
            />
        </FieldContainer>
    );
};

export default ReactSelect;
