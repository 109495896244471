import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { userRegistrationWithEmail } from "../redux/user/user.actions";
import Spinner from "../components/Spinner";
import { setError, setProgress } from "../redux/public/public.actions";

const PageContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        md:bg-sky-100
        h-[100vh]
        relative
    `}
`;

const LogoContainer = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        font-Raleway
        text-4xl
        font-bold
        text-sky-600
        md:col-span-2
        col-span-1
    `}
`;
const FormContainer = styled.div`
    ${tw`
        m-auto
        bg-white
        lg:w-[40%]
        md:w-[80%]
        w-full
        md:p-10
        p-5
        rounded-md
        lg:drop-shadow-lg
        lg:mt-[100px]
        md:mt-[50px]
    `}
`;

const RegisterForm = styled.div`
    ${tw`
        grid
        md:grid-cols-2
        grid-cols-1
        md:gap-5
        gap-3
    `}
`;

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        text-sky-600
        
    `}
    input, select {
        ${tw`
            outline-none
            md:py-2
            bg-transparent
            border-b
            
        `}
    }
`;
const AccountExist = styled.div`
    ${tw`
        md:col-span-2
        col-span-1
    `}
    a {
        ${tw`
            text-red-600
            ml-2
            underline
            text-sm
        `}
    }
`;

const ButtonContainer = styled.div`
    ${tw`
        flex
        flex-col
        mt-3
        md:col-span-2
        col-span-1
    `}
    button {
        ${tw`
            bg-sky-600
            px-10
            py-2
            text-white
            rounded-sm
            uppercase
            font-Raleway
        `}
    }
`;

const SubHeader = styled.div`
    ${tw`
        md:col-span-2
        col-span-1
        text-sky-600
        text-2xl
        flex
        text-center
        items-center
        md:justify-start
        justify-center
        w-full
    `}
`;

const mapState = ({ temp }) => ({
    loading: temp.loading,
    error: temp.error,
});

const Register = () => {
    const { loading, error } = useSelector(mapState);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        institutionName: "",
        institutionType: "Select",
        institutionCode: "",
        phone: "",
        role: "admin",
        status: "active",
    });

    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case "institutionType":
                return setFormData({
                    ...formData,
                    [name]: value,
                });

            default:
                return "unknown";
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("Registration form data", formData)
        dispatch(userRegistrationWithEmail(formData));
    };

    useEffect(() => {
        dispatch(setProgress(null));
        dispatch(setError(null));
    }, []);

    return (
        <PageContainer>
            {/* <LogoContainer>eSkole</LogoContainer> */}
            <FormContainer>
                <form onSubmit={handleSubmit}>
                    <RegisterForm>
                        <LogoContainer>
                            <Link to="/">eSkole</Link>
                        </LogoContainer>
                        <SubHeader>Create your account</SubHeader>
                        <FormField>
                            <label>Your name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="John Smith"
                                autoComplete="off"
                                required
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField>
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Eg.john.smith@domain.com"
                                autoComplete="off"
                                required
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField>
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                placeholder=""
                                autoComplete="off"
                                required
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        {/* <FormField>
                            <label>Your are</label>
                            <select name="role" value={formData.role} onChange={handleSelectChange}>
                                <option value="">Select</option>
                                <option value="owner">Owner</option>
                                <option value="manager">Manager</option>
                                <option value="principal">Principal</option>
                                <option value="teacher">Teacher</option>
                                <option value="Other">Other</option>
                            </select>
                        </FormField> */}
                        <FormField>
                            <label>Institution Name</label>
                            <input
                                type="text"
                                name="institutionName"
                                placeholder=""
                                autoComplete="off"
                                required
                                value={formData.institutionName}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField>
                            <label>Institution Type</label>
                            <select
                                name="institutionType"
                                value={formData.institutionType}
                                onChange={handleSelectChange}
                            >
                                <option value="">Select</option>
                                <option value="school">School</option>
                                <option value="college">College</option>
                                <option value="university">University</option>
                                <option value="playSchool">
                                    Play School / Kindergarten
                                </option>
                                <option value="trainingCenter">
                                    Training Center
                                </option>
                            </select>
                        </FormField>
                        <FormField>
                            <label>Phone</label>
                            <input
                                type="text"
                                name="phone"
                                placeholder=""
                                autoComplete="off"
                                required
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField>
                            <AccountExist>
                                <span>Already have an account?</span>
                                <span>
                                    <Link to="/login">Login In</Link>
                                </span>
                            </AccountExist>
                        </FormField>
                        <ButtonContainer>
                            <button type="submit">
                                {loading === true && error?.hasError !== true
                                    ? "Creating Account"
                                    : "Create Account"}
                            </button>
                            {
                                // (loading === true && error?.hasError !== true) && (
                                loading === true &&
                                    error?.hasError !== true && <Spinner />
                            }
                        </ButtonContainer>
                    </RegisterForm>
                </form>
            </FormContainer>
        </PageContainer>
    );
};

export default Register;
