import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageAccess } from "../redux/user/user.actions";
import { useNavigate } from "react-router";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import {FaQuestionCircle} from "react-icons/fa"


const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[225px]
    `}
`

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`

const WelcomeContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        items-start
        text-center
        justify-start
    `}
`

const SubHeader = styled.div`
    ${tw`
        w-full
        flex
        text-center
        items-center
        justify-start
        text-violet-800
        text-lg
        border-b
        px-2
        gap-2
    `}
`

const ServiceContainer = styled.div`
    ${tw`
        flex
        flex-col
        mt-5
        gap-5
    `}
`

const ServiceListContainer = styled.div`
    ${tw`
        grid
        lg:grid-cols-4
        md:grid-cols-3
        grid-cols-1
    `}
    a{
        ${tw`
            text-violet-800
            w-full
            flex
            items-center
            text-center
            justify-start
            md:border-b-0
            border-b
            py-2
            md:py-1
            md:text-base
            text-sm
        `}
    }
`

const TooltipContainer = styled.span`
    ${tw`
        ml-1
    `}
`

const PlanContainer = styled.div`
    ${tw`
        bg-violet-700
        p-2
        rounded-sm
    `}
`
const PlanName = styled.div`
    ${tw`
        w-full
        flex
        text-center
        items-center
        justify-between
        text-white
        font-Raleway
        text-xl
    `}
    a{
        ${tw`
            text-xs
            font-bold
            bg-amber-400
            text-violet-800
            px-2
            py-1
            rounded-sm
        `}
    }
`

const PlanDetails = styled.div`
    ${tw`
        w-full
        flex
        text-white
        text-xs
    `}
`

const mapState = ({user}) => ({
    currentUser: user.currentUser,
    hasPageAccess: user.hasPageAccess
})


const Services = ({name}) => {
    
    const {currentUser, hasPageAccess} = useSelector(mapState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        //check access to the page
        if(currentUser){
            const arg = {
                role: currentUser.role,
                page: name
            }
            dispatch(getPageAccess(arg))
        }
    }, [])

    return(
        <>
            {
                hasPageAccess === true
                ?
                    <PageContainer>
                        <Sidebar />
                        <BodyContainer>
                            <Header />
                            <BodyContentConatiner>
                                <WelcomeContainer>
                                    <SubHeader>
                                        <span>Services</span>
                                    </SubHeader>
                                </WelcomeContainer>
                                <ServiceContainer>
                                    <PlanContainer>
                                        <PlanName>
                                            <span>Basic Plan</span>
                                            <Link to="">Upgrade</Link>
                                        </PlanName>
                                        <PlanDetails>
                                            <span>
                                                Days Remaining:  90 days
                                            </span>
                                        </PlanDetails>
                                    </PlanContainer>
                                    <ServiceListContainer>
                                        <span>
                                            <Link to="">
                                                Student Managemenet
                                                <TooltipContainer><FaQuestionCircle size={12} /></TooltipContainer>
                                            </Link>
                                            </span>
                                        <span>
                                            <Link to="">
                                                Schedule Management
                                                <TooltipContainer><FaQuestionCircle size={12} /></TooltipContainer>
                                            </Link>
                                        </span>
                                        <span>
                                            <Link to="">
                                                Finance Managemenet
                                                <TooltipContainer><FaQuestionCircle size={12} /></TooltipContainer>
                                            </Link>
                                        </span>
                                        <span>
                                            <Link to="">
                                                Exams and Assessment
                                                <TooltipContainer><FaQuestionCircle size={12} /></TooltipContainer>
                                                </Link>
                                            </span>
                                        <span>
                                            <Link to="">
                                                Reporting
                                                <TooltipContainer><FaQuestionCircle size={12} /></TooltipContainer>
                                            </Link>
                                            </span>
                                    </ServiceListContainer>
                                </ServiceContainer>                            
                            </BodyContentConatiner>
                        </BodyContainer>
                    </PageContainer>
                :
                    hasPageAccess === null 
                    ?
                        "Loading"
                    :
                        navigate("/unauthorized")

            }
        </>
    )
}

export default Services