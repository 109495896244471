import { publicTypes } from "./public.types";

export const setProgress = (arg) => ({
    type: publicTypes.ACTION_SET_PROGRESS,
    payload: arg,
});

export const setError = (bool) => ({
    type: publicTypes.ACTION_SET_ERROR,
    payload: bool,
});

export const getSchoolUsers = (school) => ({
    type: publicTypes.GET_ALL_USERS_OF_SCHOOL_START,
    payload: school,
});

export const putSchoolUsers = (users) => ({
    type: publicTypes.GET_ALL_USERS_OF_SCHOOL_SUCCESS,
    payload: users,
});

export const createSchoolProfile = (arg) => ({
    type: publicTypes.CREATE_SCHOOL_PROFILE_START,
    payload: arg,
});

export const getInstitutionDetails = (schoolId) => ({
    type: publicTypes.GET_INSTITUTION_DETAILS_START,
    payload: schoolId,
});

export const putInstitutionDetails = (data) => ({
    type: publicTypes.GET_INSTITUTION_DETAILS_SUCCESS,
    payload: data,
});

export const getAllCountries = () => ({
    type: publicTypes.GET_ALL_COUNTRIES_START,
});

export const putAllCountries = (countryList) => ({
    type: publicTypes.GET_ALL_COUNTRIES_SUCCESS,
    payload: countryList,
});

export const getCountryStates = (countryId) => ({
    type: publicTypes.GET_ALL_STATES_OF_COUNTRY_START,
    payload: countryId,
});

export const putCountryStates = (stateList) => ({
    type: publicTypes.GET_ALL_STATES_OF_COUNTRY_SUCCESS,
    payload: stateList,
});

export const updateInstitutionDetails = (formData) => ({
    type: publicTypes.UPDATE_INSTITUTION_DETAILS_START,
    payload: formData,
});

export const getAllSchoolsList = (userParms) => ({
    type: publicTypes.GET_LIST_OF_ALL_SCHOOLS_START,
    payload: userParms,
});

export const putAllSchools = (schoolList) => ({
    type: publicTypes.GET_LIST_OF_ALL_SCHOOLS_SUCCESS,
    payload: schoolList,
});

export const addNewBranchStart = (branchData) => ({
    type: publicTypes.ADD_NEW_SCHOOL_BRANCH_START,
    payload: branchData,
});

export const addNewBranchSuccess = () => ({
    type: publicTypes.ADD_NEW_SCHOOL_BRANCH_SUCCESS,
});

export const getSchoolBranches = (schoolId) => ({
    type: publicTypes.GET_SCHOOL_BRANCHES_START,
    payload: schoolId,
});

export const putSchoolBranches = (branchList) => ({
    type: publicTypes.GET_SCHOOL_BRANCHES_SUCCESS,
    payload: branchList,
});

export const getAllClasses = () => ({
    type: publicTypes.GET_ALL_CLASSES_START,
});

export const putAllClasses = (classList) => ({
    type: publicTypes.GET_ALL_CLASSES_SUCCESS,
    payload: classList,
});

export const validateClassSection = (args) => ({
    type: publicTypes.VALIDATE_CLASS_SECTION_START,
    payload: args,
});

export const getClassSectionsOfBranch = (args) => ({
    type: publicTypes.GET_CLASS_SECTIONS_OF_BRANCH_START,
    payload: args,
});

export const putClassSectionsOfBranch = (classList) => ({
    type: publicTypes.GET_CLASS_SECTIONS_OF_BRANCH_SUCCESS,
    payload: classList,
});

export const getAllSubjects = () => ({
    type: publicTypes.GET_ALL_SUBJECTS_START,
});

export const putAllSubjects = (subjectList) => ({
    type: publicTypes.GET_ALL_SUBJECTS_SUCCESS,
    payload: subjectList,
});
