import moment from "moment";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const FooterContainer = styled.div`
    ${tw`
        flex
        flex-col
        // bg-violet-600
        bg-sky-600
        lg:px-[115px]
        px-[30px]
        md:py-10
        py-5
    `}
    span{
        ${tw`
            text-2xl
            font-Raleway
            font-bold
            text-amber-400
        `}
    }
    p{
       ${tw`
            text-white
            md:text-sm
            text-xs
       `}
    }
`

const FooterSection = () => {
    return(
        <FooterContainer>
            <span>eSkole</span>
            <p>{`Copyright © eSkole${moment(new Date()).format("YYYY")}. All rights reserved`}</p>
        </FooterContainer>
    )
}

export default FooterSection