import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useDispatch, useSelector } from "react-redux";
import {
    getCountryStates,
    validateClassSection,
} from "../redux/public/public.actions";
import Spinner from "../components/Spinner";
import ReactSelect from "./ReactSelect";

const DetailsContainer = styled.div`
    ${tw`
        grid
        lg:grid-cols-2
        lg:px-4
        lg:gap-4
        md:grid-cols-2
        grid-cols-2
    `}
`;

const FormField = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        py-2
        text-violet-800
    `}
    span {
        ${tw`
            font-Poppins
            text-sm
        `}
    }
    input,
    select {
        ${tw`
            py-1
            border-b
            border-slate-200
            outline-none
            focus:border-b-violet-400
            text-sm
            font-medium
        `}
    }
`;

const LabelContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-1
    `}
`;

const RequiredField = styled.span`
    ${tw`
        text-red-600
        text-sm
    `}
`;

const ButtonContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-start
        mt-2
        gap-2
    `}
    button {
        ${tw`
            uppercase
            text-xs
            font-medium
            bg-violet-600
            text-white
            hover:bg-amber-400
            hover:text-violet-800
            px-4
            py-2
            rounded-sm
        `}
    }
`;

const HeadingLevel3 = styled.div`
    ${tw`
        w-full
        lg:col-span-2
        md:col-span-2
        col-span-2
        mt-3
        text-slate-600
        font-Poppins
        flex
        text-center
        items-center
        justify-start
    `}
`;

const ErrorMessage = styled.div`
    ${tw`
        flex
        w-full
        text-red-600
        text-xs
        py-1
        mt-1
        font-bold
    `}
`;

const mapState = ({ user, temp }) => ({
    schoolBranches: temp.schoolBranches,
    allClasses: temp.allClasses,
    nextSection: temp.nextSection,
    currentUser: user.currentUser,
    error: temp.error,
    loading: temp.loading,
    allSubjects: temp.allSubjects,
});

const AddClass = () => {
    const dispatch = useDispatch();
    const {
        schoolBranches,
        allClasses,
        currentUser,
        loading,
        error,
        allSubjects,
    } = useSelector(mapState);

    const [formData, setFormData] = useState({
        institutionCode: currentUser.institutionCode,
        institutionBranch: "",
        branchClass: "",
        mediumOfInstruction: "",
        classSection: "",
        classMandatorySubjects: [],
        classOptionalSubjects: [],
    });

    const sectionOptions = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const [selectedCountry, setSelectedCountry] = useState();

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index];
        const option = el.getAttribute("id");

        console.log("Selected Id is:", option);

        switch (name) {
            case "institutionBranch":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchClass":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchBoard":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchCountry":
                setSelectedCountry(option);
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchState":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "mediumOfInstruction":
                setFormData({
                    ...formData,
                    [name]: value,
                });
            default:
                return null;
        }
    };

    useEffect(() => {
        if (selectedCountry) {
            dispatch(getCountryStates(selectedCountry));
        }
    }, [selectedCountry]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Our form data", formData);
        const dt = {
            vClass: formData.branchClass,
            vSection: formData.classSection,
            vBranch: formData.institutionBranch,
            vInstitution: formData.institutionCode,
            vMediumOfInstruction: formData.mediumOfInstruction,
            vMandatorySubjects: formData.classMandatorySubjects,
            vOptionalSubjects: formData.classOptionalSubjects,
        };
        dispatch(validateClassSection(dt));
    };

    const handleMandatorySubjects = (subjcts) => {
        let arr = [];
        subjcts.forEach((subj) => {
            const dt = {
                ...subj,
                mandatory: "Y",
            };
            arr.push(dt);
        });
        setFormData({
            ...formData,
            classMandatorySubjects: arr,
        });
    };

    const handleOptionalSubjects = (subjcts) => {
        let arr = [];
        subjcts.forEach((subj) => {
            const dt = {
                ...subj,
                mandatory: "N",
            };
            arr.push(dt);
        });
        setFormData({
            ...formData,
            classOptionalSubjects: arr,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <DetailsContainer>
                <HeadingLevel3>Basic Details</HeadingLevel3>
                <FormField>
                    <LabelContainer>
                        <span>Branch</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="institutionBranch"
                        required
                        value={formData?.institutionBranch}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {schoolBranches &&
                            schoolBranches.length > 0 &&
                            schoolBranches.map((branch) => {
                                return (
                                    <option key={branch.uuid} id={branch.uuid}>
                                        {branch.branchName}
                                    </option>
                                );
                            })}
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Class</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="branchClass"
                        required
                        value={formData?.branchClass}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {allClasses &&
                            allClasses.length > 0 &&
                            allClasses.map((cls) => {
                                return (
                                    <option key={cls.name}>{cls.name}</option>
                                );
                            })}
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Section</span>
                    </LabelContainer>
                    <input
                        type="text"
                        name="classSection"
                        value={formData.classSection}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                    />
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Medium Of Instruction</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="mediumOfInstruction"
                        required
                        value={formData?.mediumOfInstruction}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                    </select>
                </FormField>
            </DetailsContainer>
            {allSubjects.length > 0 && (
                <>
                    <ReactSelect
                        name="classMandatorySubjects"
                        labelText="Mandatory Subjects"
                        required={true}
                        optns={allSubjects}
                        handleMultiChange={handleMandatorySubjects}
                    />
                    <ReactSelect
                        name="classOptionalSubjects"
                        labelText="Optional Subjects"
                        required={false}
                        optns={allSubjects}
                        handleMultiChange={handleOptionalSubjects}
                    />
                </>
            )}

            <ButtonContainer>
                <button type="submit">
                    {loading === true && error?.hasError !== true
                        ? "Saving"
                        : "Save"}
                </button>
                {loading === true && error?.hasError !== true && <Spinner />}
            </ButtonContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </form>
    );
};

export default AddClass;
