import React from "react";
import { IconContext } from 'react-icons';
import * as FaIcons from "react-icons/fa"
import * as BsIcons from "react-icons/bs"
import * as MdIcons from "react-icons/md"

const DynamicIcon = ({iconName,iconSize, library}) =>{
    let IconComponent = ''
    switch(library){
        case "fa":
            IconComponent = FaIcons[iconName]
            break
            // return(IconComponent)
        case "bs":
            IconComponent = BsIcons[iconName]
            // return(IconComponent)
            break
        case "md":
            IconComponent = MdIcons[iconName]
            // return(IconComponent)
            break
        default:
            IconComponent = null
    }
    // IconComponent = FaIcons[iconName]

    if(!IconComponent) {
        return <div>Icon not found</div>;
    }

    return(
        <IconContext.Provider value={{ size: `${iconSize}`}}>
            <IconComponent />
        </IconContext.Provider>
    )
}

export default DynamicIcon