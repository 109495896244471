import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageAccess } from "../redux/user/user.actions";
import { useNavigate } from "react-router";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import {FaQuestionCircle} from "react-icons/fa"
import { Switch } from "@material-ui/core";


const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[225px]
    `}
`

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`

const WelcomeContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        items-start
        text-center
        justify-start
    `}
`

const SubHeader = styled.div`
    ${tw`
        w-full
        flex
        text-center
        items-center
        justify-start
        text-violet-800
        text-lg
        border-b
        px-2
        gap-2
    `}
`

const SettingsContainer = styled.div`
    ${tw`
        w-full
        mt-5
        text-violet-800
    `}
`

const SectionContainer = styled.div`
    ${tw`
        w-full
    `}
`

const SectionTitle = styled.div`
    ${tw`
        w-full
        bg-slate-200
        py-1
        px-2
    `}
`

const SectionFields = styled.div`
    ${tw`
        w-full
        p-2
    `}
`


const FormField = styled.div`
    ${tw`
        flex
        gap-2
    `}
    input{
        ${tw`
            border
            border-violet-800
            rounded-sm
            focus:outline-2
            focus:outline-violet-800
        `}
    }
`

const mapState = ({user}) => ({
    currentUser: user.currentUser,
    hasPageAccess: user.hasPageAccess
})


const Settings = ({name}) => {
    
    const {currentUser, hasPageAccess} = useSelector(mapState)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        lateFees:'',
        school:'',

    })

    useEffect(()=>{
        //check access to the page
        if(currentUser){
            const arg = {
                role: currentUser.role,
                page: name
            }
            dispatch(getPageAccess(arg))
        }
    }, [])

    return(
        <>
            {
                hasPageAccess === true
                ?
                    <PageContainer>
                        <Sidebar />
                        <BodyContainer>
                            <Header />
                            <BodyContentConatiner>
                                <SubHeader>
                                    <span>Settings</span>
                                </SubHeader>
                                <SettingsContainer>
                                    <SectionContainer>
                                        <form>
                                            <SectionFields>
                                                <FormField>
                                                    <label>Late Fees</label>
                                                    <input
                                                        type="text"
                                                        value=''
                                                        autoComplete="off"
                                                    />
                                                </FormField>
                                            </SectionFields>
                                        </form>
                                    </SectionContainer>
                                </SettingsContainer>                        
                            </BodyContentConatiner>
                        </BodyContainer>
                    </PageContainer>
                :
                    hasPageAccess === null 
                    ?
                        "Loading"
                    :
                        navigate("/unauthorized")

            }
        </>
    )
}

export default Settings