import {initializeApp} from "firebase/app"
import {addDoc, collection, getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth"
import {firebaseConfig} from "../firebase/config"
import {getStorage, ref, getDownloadURL} from "firebase/storage"

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)
export const storage = getStorage(app)


export const addQuery = async (data) => {
    console.log("Executing fuction", data)
    const queryRef = collection(db,"query")
    
    try {
        const queryRef = collection(db,"query")
        const res = await addDoc(queryRef,data)
        return true
    } catch (error) {
        return false
    }
}