import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useDispatch, useSelector } from "react-redux";
import {
    addNewBranchStart,
    getCountryStates,
} from "../redux/public/public.actions";
import Spinner from "../components/Spinner";

const DetailsContainer = styled.div`
    ${tw`
        grid
        lg:grid-cols-2
        lg:px-4
        lg:gap-4
        md:grid-cols-2
        grid-cols-2
    `}
`;

const FormField = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        py-2
        text-violet-800
    `}
    span {
        ${tw`
            font-Poppins
            text-sm
        `}
    }
    input,
    select {
        ${tw`
            py-1
            border-b
            border-slate-200
            outline-none
            focus:border-b-violet-400
            text-sm
            font-medium
        `}
    }
`;

const LabelContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-1
    `}
`;

const RequiredField = styled.span`
    ${tw`
        text-red-600
        text-sm
    `}
`;

const ButtonContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-start
        mt-2
        gap-2
    `}
    button {
        ${tw`
            uppercase
            text-xs
            font-medium
            bg-violet-600
            text-white
            hover:bg-amber-400
            hover:text-violet-800
            px-4
            py-2
            rounded-sm
        `}
    }
`;

const HeadingLevel3 = styled.div`
    ${tw`
        w-full
        lg:col-span-2
        md:col-span-2
        col-span-2
        mt-3
        text-slate-600
        font-Poppins
        flex
        text-center
        items-center
        justify-start
    `}
`;

const mapState = ({ temp }) => ({
    allSchools: temp.allSchools,
    allCountries: temp.allCountries,
    countryStates: temp.countryStates,
    loading: temp.loading,
    error: temp.error,
});

const AddBranch = () => {
    const dispatch = useDispatch();
    const { allSchools, allCountries, countryStates, loading, error } =
        useSelector(mapState);

    const [formData, setFormData] = useState({
        branchName: "",
        branchInstitution:
            allSchools && allSchools.length == 1 ? allSchools[0] : "",
        branchBoard: "",
        mediumOfInstruction: "",
        branchPhone: "",
        branchAddress: "",
        branchCountry: "",
        branchState: "",
        branchCity: "",
    });
    const [selectedCountry, setSelectedCountry] = useState();

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index];
        const option = el.getAttribute("id");

        console.log("Selected Id is:", option);

        switch (name) {
            case "branchInstitution":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchBoard":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchCountry":
                setSelectedCountry(option);
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "branchState":
                setFormData({
                    ...formData,
                    [name]: value,
                });
                break;
            case "mediumOfInstruction":
                setFormData({
                    ...formData,
                    [name]: value,
                });
            default:
                return null;
        }
    };

    useEffect(() => {
        if (selectedCountry) {
            dispatch(getCountryStates(selectedCountry));
        }
    }, [selectedCountry]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Our form data", formData);
        dispatch(addNewBranchStart(formData));
    };

    return (
        <form onSubmit={handleSubmit}>
            <DetailsContainer>
                <HeadingLevel3>Basic Details</HeadingLevel3>
                <FormField>
                    <LabelContainer>
                        <span>Branch Name</span>
                    </LabelContainer>
                    <input
                        type="text"
                        name="branchName"
                        value={formData?.branchName}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                    />
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Institition</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="branchInstitution"
                        required
                        value={formData?.branchInstitution}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {allSchools &&
                            allSchools.length > 0 &&
                            allSchools.map((school) => {
                                return (
                                    <option
                                        id={school.institutionCode}
                                        key={school.institutionCode}
                                        value={school.institutionCode}
                                    >
                                        {school.institutionName}
                                    </option>
                                );
                            })}
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Board</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="branchBoard"
                        required
                        value={formData?.branchBoard}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        <option value="ISC">ISC/ICSE</option>
                        <option value="CBSE">CBSE</option>
                        <option value="State Board">State Board</option>
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Medium Of Instruction</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="mediumOfInstruction"
                        required
                        value={formData?.mediumOfInstruction}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Phone</span>
                    </LabelContainer>
                    <input
                        type="text"
                        name="branchPhone"
                        value={formData?.branchPhone}
                        onChange={handleInputChange}
                        required
                        placeholder="+91 XXXXXXXXXX"
                        autoComplete="off"
                    />
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Address</span>
                    </LabelContainer>
                    <input
                        type="text"
                        name="branchAddress"
                        value={formData?.branchAddress}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                    />
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>Country</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="branchCountry"
                        required
                        value={formData?.branchCountry}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {allCountries &&
                            allCountries.length > 0 &&
                            allCountries.map((country) => {
                                return (
                                    <option
                                        id={country.uuid}
                                        key={country.uuid}
                                    >
                                        {country.name}
                                    </option>
                                );
                            })}
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>State / Province</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <select
                        name="branchState"
                        required
                        value={formData?.branchState}
                        onChange={handleSelectChange}
                    >
                        <option value="" disabled></option>
                        {countryStates &&
                            countryStates.length > 0 &&
                            countryStates.map((state) => {
                                return (
                                    <option id={state.uuid} key={state.uuid}>
                                        {state.name}
                                    </option>
                                );
                            })}
                    </select>
                </FormField>
                <FormField>
                    <LabelContainer>
                        <span>City</span>
                        <RequiredField>*</RequiredField>
                    </LabelContainer>
                    <input
                        type="text"
                        name="branchCity"
                        required
                        value={formData?.branchCity}
                        onChange={handleInputChange}
                    />
                </FormField>
            </DetailsContainer>
            <ButtonContainer>
                <button type="submit">
                    {loading === true && error?.hasError !== true
                        ? "Saving"
                        : "Save"}
                </button>
                {loading === true && error?.hasError !== true && <Spinner />}
            </ButtonContainer>
        </form>
    );
};

export default AddBranch;
