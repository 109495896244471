import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import RouteShield from "./components/RouteShield";
import Unauthorized from "./pages/UnAuthorized";
import Users from "./pages/Users";
import Services from "./pages/Services";
import Settings from "./pages/Settings";
import MyOrganisation from "./pages/MyOrganisation";
import Branches from "./pages/Branches";
import Classes from "./pages/Classes";
import AddClass from "./components/AddClass";
import Students from "./pages/Students";
import Vendors from "./pages/Vendors";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route element={<RouteShield />}>
                    <Route path="/home" element={<Home name="/home" />} />
                    <Route path="/users" element={<Users name="/users" />} />
                    <Route
                        path="/subscription"
                        element={<Services name="/subscription" />}
                    />
                    <Route
                        path="/settings"
                        element={<Settings name="/settings" />}
                    />
                </Route>
                <Route
                    path="/myorganisation"
                    element={<MyOrganisation name="myorganisation" />}
                />
                <Route
                    path="/branches"
                    element={<Branches name="branches" />}
                />
                <Route path="/classes" element={<Classes name="classes" />} />
                <Route
                    path="/students"
                    element={<Students name="students" />}
                />
                <Route path="/vendors" element={<Vendors name="vendors" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
