import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { MdClose } from "react-icons/md";

const ModalContainer = styled.div`
    /* position: relative;
    max-width: 20rem;
    padding: 2rem;
    border: 0;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%); */
    ${tw`
        absolute
        top-0
        left-0
        min-h-screen
        w-full
        bg-[rgba(0,0,0,0.3)]
        md:flex
        items-center
        text-center
        justify-center
        hidden
    `}
`;

const ModalDialog = styled.dialog`
    ${tw`
        // relative
        md:m-auto
        p-2
        rounded-sm
        text-violet-800
    `}
`;

const ModalHeadContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-between
        border-b
        py-1
        border-b-gray-200
    `}
`;

const ModalTitleContainer = styled.span`
    ${tw`
        font-Poppins
        font-medium
        text-lg
    `}
`;

const ModalBodyContainer = styled.div`
    ${tw`
        w-full
        py-2
    `}
`;

const ModalCloseButtonContainer = styled.div`
    ${tw`
        cursor-pointer
        hover:bg-violet-800
        hover:rounded-full
        hover:text-white
        p-1
    `}
`;

const CustomModal = ({ openState, onClose, title, children }) => {
    const [isOpen, setIsOpen] = useState(openState);
    console.log("Current state of Moda:", isOpen);

    const [modalTitle, setModalTitle] = useState(title);
    // const modalRef = useRef();

    // useEffect(() => {
    //     setIsOpen(openState);
    // }, [openState]);

    // useEffect(() => {
    //     const modalElement = modalRef.current;
    //     if (modalElement) {
    //         if (isOpen) {
    //             modalElement.showModal();
    //         } else {
    //             modalElement.close();
    //         }
    //     }
    // }, [isOpen]);

    // const handleCloseModal = () => {
    //     if (onClose) {
    //         onClose();
    //     }
    //     setIsOpen(false);
    // };

    // const handleKeyDown = (e) => {
    //     if (e.key === "Escape") {
    //         handleCloseModal();
    //     }
    // };

    return (
        <ModalContainer>
            <ModalDialog open={true} onClose={onClose}>
                <ModalHeadContainer>
                    <ModalTitleContainer>{modalTitle}</ModalTitleContainer>
                    <ModalCloseButtonContainer>
                        <MdClose onClick={onClose} />
                    </ModalCloseButtonContainer>
                </ModalHeadContainer>
                <ModalBodyContainer>{children}</ModalBodyContainer>
            </ModalDialog>
        </ModalContainer>
    );
};

export default CustomModal;
