import { all, call, put, takeLatest } from "redux-saga/effects";
import { userTypes } from "./user.types";
import { auth, db } from "../../firebase/utility";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import {
    and,
    collection,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    where,
} from "firebase/firestore";
import {
    emailSignInSuccess,
    putPageAccess,
    putUserMenuList,
    putUserSubMenuList,
    userSignOutSuccess,
} from "./user.actions";
import {
    createSchoolProfile,
    setError,
    setProgress,
} from "../public/public.actions";
import { v4 as uuid } from "uuid";

//=============================================================================
//1. USER AUTHENTICATION USING EMAIL AND PASSWORD - START
//=============================================================================
export function* getSnapshotOfUser(user) {
    const { uid } = user;
    const userRef = doc(db, "users", `${uid}`);
    const snapshot = yield getDoc(userRef);
    const { _document } = snapshot;

    if (!_document) {
        return;
    }

    return snapshot;
}

export function* userAuth({ payload: { email, password } }) {
    yield put(setProgress(true));

    yield put(setError(null));

    try {
        const { user } = yield signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const snap = yield getSnapshotOfUser(user);
        const dt = snap.data();
        yield put(
            emailSignInSuccess({
                id: snap.id,
                ...snap.data(),
            })
        );
        // console.log(user)
    } catch (error) {
        // console.log(error.code)
        // return("error")
        yield put(
            setError({
                hasError: true,
                code: error.code,
            })
        );
    }

    yield put(setProgress(false));
}

export function* onUserAuthenticationStart() {
    yield takeLatest(userTypes.USER_AUTHENTICATION_START, userAuth);
}
//=============================================================================
//1. USER AUTHENTICATION USING EMAIL AND PASSWORD - END
//=============================================================================

//=============================================================================
//2. USER REGISTRATION USING EMAIL AND PASSWORD - START
//=============================================================================
export function* registerWithEmail({ payload }) {
    yield put(setProgress(true));

    yield put(setError(null));

    try {
        const user = yield createUserWithEmailAndPassword(
            auth,
            payload.email,
            payload.password
        );
        // console.log("Created User", user.user.uid)

        //ADD USER DATA AFTER REGISTRATION
        try {
            const schoolCode = uuid();
            const userRef = doc(db, "users", `${user.user.uid}`);
            yield setDoc(userRef, {
                ...payload,
                uuid: uuid(),
                institutionCode: schoolCode,
            });
            yield put(setError(null));

            yield put(createSchoolProfile({ payload, schoolCode }));
        } catch (error) {
            // console.log(error)
            yield put(setError(error.code));
        }
    } catch (error) {
        // console.log(error)
        yield put(
            setError({
                hasError: true,
                code: error.code,
            })
        );
    }

    yield put(setProgress(false));
}

export function* onUserRegistrationWithEmailStart() {
    yield takeLatest(
        userTypes.USER_REGISTRATION_WITH_EMAIL_START,
        registerWithEmail
    );
}
//=============================================================================
//2. USER REGISTRATION USING EMAIL AND PASSWORD - END
//=============================================================================

//=============================================================================
//3. GET USER ROLE AND PERMISSIONS - START
//=============================================================================
export function* userRolesAndPermission({ payload }) {
    console.log("Role and permission for user:", payload);
    let hasAccess = null;
    try {
        const userRef = collection(db, "pages");
        const qry = query(
            userRef,
            and(
                where("role", "==", payload.role),
                where("name", "==", payload.page)
            )
        );
        const { _snapshot } = yield getDocs(qry);
        const pages = _snapshot.docChanges;

        if (Array.isArray(pages) && pages.length > 0) {
            hasAccess = true;
        } else {
            hasAccess = false;
        }

        yield put(putPageAccess(hasAccess));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetUserRolesAndPermissionStart() {
    yield takeLatest(
        userTypes.GET_USER_PAGE_ACCESS_START,
        userRolesAndPermission
    );
}
//=============================================================================
//3. GET USER ROLE AND PERMISSIONS - END
//=============================================================================

//=============================================================================
//4. GET USER MENU LIST - START
//=============================================================================
export function* processMenuData(rawMenuData) {
    let menuArr = [];
    if (Array.isArray(rawMenuData)) {
        rawMenuData.forEach((menu) => {
            const menuItem = {
                id: menu.doc.key.path.segments[6],
                uuid: menu.doc.data.value.mapValue.fields.uuid.stringValue,
                name: menu.doc.data.value.mapValue.fields.name.stringValue,
                icon: menu.doc.data.value.mapValue.fields.icon.stringValue,
                hasSubmenu:
                    menu.doc.data.value.mapValue.fields.hasSubmenu.booleanValue,
                path: menu.doc.data.value.mapValue.fields.path.stringValue,
            };
            menuArr.push(menuItem);
        });
    }
    return menuArr;
}

export function* userMenuList({ payload }) {
    console.log("Getting user menu", payload);
    try {
        const menuRef = collection(db, "menu");
        const qry = query(menuRef, orderBy("order"));
        const { _snapshot } = yield getDocs(qry);
        const menuList = _snapshot.docChanges;

        const processedMenu = yield processMenuData(menuList);
        console.log("Processed menu", processedMenu);
        yield put(putUserMenuList(processedMenu));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetUserMenuListStart() {
    yield takeLatest(userTypes.GET_USER_MENU_LIST_START, userMenuList);
}
//=============================================================================
//4. GET USER MENU LIST - END
//=============================================================================

//=============================================================================
//5. GET USER SUBMENU LIST - START
//=============================================================================
export function* processSubMenuData(rawSubMenuData) {
    let submenuArr = [];
    if (Array.isArray(rawSubMenuData)) {
        rawSubMenuData.forEach((submenu) => {
            const submenuItem = {
                id: submenu.doc.key.path.segments[6],
                uuid: submenu.doc.data.value.mapValue.fields.uuid.stringValue,
                name: submenu.doc.data.value.mapValue.fields.name.stringValue,
                path: submenu.doc.data.value.mapValue.fields.path.stringValue,
            };
            submenuArr.push(submenuItem);
        });
    }
    return submenuArr;
}

export function* userSubMenuList({ payload }) {
    console.log("Getting user submenu", payload);
    try {
        const menuRef = collection(db, "submenu");
        const qry = query(
            menuRef,
            where("menuId", "==", payload),
            orderBy("order", "asc")
        );
        const { _snapshot } = yield getDocs(qry);
        const submenuList = _snapshot.docChanges;

        const processedSubMenu = yield processSubMenuData(submenuList);
        console.log("Processed submenu", processedSubMenu);
        yield put(putUserSubMenuList(processedSubMenu));
    } catch (error) {
        console.log(error);
    }
}

export function* onGetUserSubMenuListStart() {
    yield takeLatest(userTypes.GET_USER_SUBMENU_LIST_START, userSubMenuList);
}
//=============================================================================
//5. GET USER SUBMENU LIST - END
//=============================================================================

//=============================================================================
//6. USER SIGN OUT - START
//=============================================================================
export function* usersSignOut({ payload }) {
    try {
        yield signOut(auth);
        yield put(userSignOutSuccess());
    } catch (error) {
        console.log(error);
    }
}

export function* onUserSignOutStart() {
    yield takeLatest(userTypes.USER_SIGNOUT_START, usersSignOut);
}

//=============================================================================
//6. USER SIGN OUT - END
//=============================================================================
export default function* userSagas() {
    yield all([
        call(onUserAuthenticationStart),
        call(onUserRegistrationWithEmailStart),
        call(onGetUserRolesAndPermissionStart),
        call(onGetUserMenuListStart),
        call(onGetUserSubMenuListStart),
        call(onUserSignOutStart),
    ]);
}
