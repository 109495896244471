import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import {FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLinkedinIn, FaUserGraduate, FaUserTie} from "react-icons/fa"

const HeaderContainer = styled.div`
    ${tw`
        w-full
        flex
        items-center
        justify-between
        // bg-red-100
        lg:px-[115px]
        px-[20px]
        py-3
    `}
`

const LogoContainer = styled.div`
    ${tw`
        w-full
        // text-violet-600
        text-sky-600
    `}
    h1{
        ${tw`
            font-[Raleway]
            font-bold
            text-3xl
        `}
    }
`

const SocialContainer = styled.div`
    ${tw`
        w-full
        md:flex
        hidden
        items-center
        justify-end 
    `}
    span{
        ${tw`
            p-3
            // text-violet-600
            text-sky-600
            hover:cursor-pointer
            // hover:bg-violet-500
            hover:bg-sky-600
            hover:text-white
            hover:rounded-full
        `}
    }
`

const LandingHeader = () => {
    return(
        <>
            <HeaderContainer>
                <LogoContainer>    
                    <h1>eSKOLE</h1>
                    <span>Global School Management Suite</span>
                </LogoContainer>
                <SocialContainer>
                    <span><FaFacebookF size={16} /></span>
                    <span><FaInstagram size={16} /></span>
                    <span><FaTwitter size={16} /></span>
                    <span><FaYoutube size={16} /></span>
                    <span><FaLinkedinIn size={16} /></span>
                </SocialContainer>
            </HeaderContainer>
        </>
    )
}

export default LandingHeader