import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DynamicIcon from "../components/DynamicReactIcon";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import AddBranch from "../components/AddBranch";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllCountries,
    getAllSchoolsList,
    getSchoolBranches,
    setError,
    setProgress,
} from "../redux/public/public.actions";

const PageContainer = styled.div`
    ${tw`
        w-full
    `}
`;

const BodyContainer = styled.div`
    ${tw`
        lg:pl-[65px]
    `}
`;

const BodyContentConatiner = styled.div`
    ${tw`
        w-full
        p-2
    `}
`;

const SubHeadingContainer = styled.div`
    ${tw`
        w-full
        bg-slate-200
        p-4
        rounded-sm
        flex
        items-center
        text-center
        justify-between
        text-gray-600
        box-border
    `}
    span {
        ${tw`
            uppercase
            text-sm
            font-bold

        `}
    }
`;

const SubHeadingTitleContainer = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-start
        gap-3
    `}
`;

const AddBranchButton = styled.div`
    ${tw`
        md:flex
        items-center
        text-center
        justify-center
        text-xs
        font-medium
        uppercase
        bg-violet-600
        text-white
        py-1
        px-2
        hidden
    `}
    ::backdrop {
        background: hsl(0 0% 0% / 50%);
    }
`;

const mapState = ({ user, temp }) => ({
    currentUser: user.currentUser,
    loading: temp.loading,
    error: temp.error,
    schoolBranches: temp.schoolBranches,
});

const Branches = () => {
    const { currentUser, loading, error, schoolBranches } =
        useSelector(mapState);
    const userRole = currentUser.role ? currentUser.role : null;
    const [openModal, setOpenModal] = useState(null);

    const branchTableColumns = [
        {
            id: "branchName",
            accessor: "branchName", //normal recommended usage of an accessorKey
            Header: "Branch",
        },
        {
            id: "branchBoard", //example of dot notation used to access nested data
            accessor: "branchBoard", //example of dot notation used to access nested data
            Header: "Board",
        },
        {
            id: "mediumOfInstruction", //example of dot notation used to access nested data
            accessor: "mediumOfInstruction", //example of dot notation used to access nested data
            Header: "MOI",
        },
        {
            id: "branchCity",
            accessor: "branchCity",
            Header: "City",
        },
        {
            id: "branchState",
            accessor: "branchState",
            Header: "State",
        },
    ];

    const dt = [
        {
            branchName: "EMS",
            branchBoard: "CBSE",
            mediumOfInstruction: "English",
            branchCity: "Lucknow",
            branchState: "Uttar Pradesh",
        },
    ];

    const dispatch = useDispatch();

    const handleBranchModal = (e) => {
        e.preventDefault();
        const userParams = {
            userRole: userRole,
            userSchoolName: currentUser.institutionName,
            userSchoolCode: currentUser.institutionCode,
        };
        dispatch(getAllSchoolsList(userParams));
        dispatch(getAllCountries());
        dispatch(setProgress(null));
        dispatch(setError(null));
        setOpenModal(true);
    };

    const handleClose = () => {
        dispatch(getSchoolBranches(currentUser.institutionCode));
        setOpenModal(false);
    };

    useEffect(() => {
        dispatch(getSchoolBranches(currentUser.institutionCode));
    }, []);

    useEffect(() => {
        if (openModal && loading === false && error === null) {
            setOpenModal(false);
        }
    }, [openModal, loading, error]);

    // console.log("Table Data is:", br);
    return (
        <PageContainer>
            <Sidebar />
            <BodyContainer>
                <Header />
                <BodyContentConatiner>
                    <SubHeadingContainer>
                        <SubHeadingTitleContainer>
                            <DynamicIcon
                                iconName="BsBuilding"
                                iconSize="1rem"
                                library="bs"
                            />
                            <span>Branches</span>
                        </SubHeadingTitleContainer>
                        <AddBranchButton>
                            <Link to="#" onClick={handleBranchModal}>
                                Add Branch
                            </Link>
                        </AddBranchButton>
                    </SubHeadingContainer>
                    {schoolBranches.length > 0 && (
                        <Table
                            tdata={schoolBranches}
                            cols={branchTableColumns}
                        />
                    )}
                </BodyContentConatiner>
                {openModal && (
                    <CustomModal
                        openState={openModal}
                        onClose={handleClose}
                        title="Add New Branch"
                    >
                        <AddBranch />
                    </CustomModal>
                )}
            </BodyContainer>
        </PageContainer>
    );
};

export default Branches;
